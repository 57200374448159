import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunkMiddleware from "redux-thunk";

import terms from "./Terms/reducer";
import tutorialVideos from "./TutorialVideos/reducer";
import cookies from "./Cookies/reducer";
import pressReleases from "./PressRelease/reducer";
import certificates from "./Certificates/reducer";
import supportLines from "./SupportLines/reducer";

const rootReducer = combineReducers({
  terms,
  tutorialVideos,
  cookies,
  pressReleases,
  certificates,
  supportLines
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunkMiddleware))
);
