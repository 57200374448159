import React, { useRef } from "react";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Assets
import rectangleBackground from "../../assets/backgrounds/rectangle-blue-bg.png";
import phoneBackground from "../../assets/backgrounds/evidence-phone-bg.png";

// Hooks
import useSelectTransition from "../../hooks/useSelectTransition";

// Contents
import HomeSubsectionNavButton from "../HomeSubsection/HomeSubsectionNavButton";
import HomeSubsection from "../HomeSubsection/HomeSubsection";
import { useIsInViewportStatic } from "../../hooks/useIsInViewport";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
    marginTop: theme.spacing(3)
  },
  navPanel: {
    position: "absolute",
    right: "45vw",
    top: -70
  },
  navContent: {
    position: "relative",
    width: 654,
    height: 1099,
    [theme.breakpoints.down("md")]: {
      width: 475,
      height: 810
    }
  },
  rectangleBackground: {
    pointerEvents: "none",
    position: "absolute",
    zIndex: 1,
    top: 330,
    left: -90,
    width: 810,
    [theme.breakpoints.up("xl")]: {
      top: 330
    },
    [theme.breakpoints.down("md")]: {
      top: 210,
      left: -70,
      width: 580
    }
  },
  navBackground: {
    pointerEvents: "none",
    position: "absolute",
    zIndex: 10,
    width: "110%",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  buttonList: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    top: "27%",
    left: "19%",
    zIndex: 40,
    [theme.breakpoints.down("md")]: {
      top: "24%",
      left: "18%"
    }
  },
  button: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: theme.spacing(1.2),
    padding: theme.spacing(3),
    backgroundColor: "#fff",
    boxShadow: "0px 1px 3px #0000000A",
    transition: "all .2s ease-in-out",
    width: 356,
    position: "relative"
  },
  buttonSelected: {
    boxShadow: "0px 1px 63px #0000001C",
    transform: "translateX(60px)"
  },
  arrowRight: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3)
  },
  iconAddLight: {
    marginRight: theme.spacing(2)
  },
  contentPanel: {
    marginLeft: "50%",
    width: "50%",
    zIndex: 99,
    paddingTop: 50,
    paddingLeft: 50,
    maxHeight: 1500,
    transition: "max-height .5s ease-in-out",
    overflow: "hidden"
  },
  transitioning: {
    maxHeight: 0
  },
  image: {
    width: 410,
    [theme.breakpoints.down("md")]: {
      width: 273
    }
  }
}));

const EvidenceCollectionDesktop = ({ subsections }) => {
  const { selectedItem, selectedIndex, transitioning, setIndex } =
    useSelectTransition();
  const classes = useStyles();
  const ref = useRef();
  const isInViewport = useIsInViewportStatic(ref);

  const selectedSection = subsections[selectedIndex];

  return (
    <div className={classes.root}>
      <div className={classes.navPanel}>
        <div className={classes.navContent}>
          <img
            alt=""
            className={classes.rectangleBackground}
            src={rectangleBackground}
          />
          <img alt="" className={classes.navBackground} src={phoneBackground} />
          <div className={classes.buttonList} ref={ref}>
            {subsections.map(({ heading, description, id }, index) => (
              <HomeSubsectionNavButton
                delay={index * 100}
                description={description}
                isInViewport={isInViewport}
                isLast={index === subsections.length - 1}
                isSelected={selectedItem === index}
                key={id}
                onClick={() => setIndex(index)}
                title={heading}
              />
            ))}
          </div>
        </div>
      </div>
      <div
        className={clsx(classes.contentPanel, {
          [classes.transitioning]: transitioning || !isInViewport
        })}
      >
        {selectedSection.content || (
          <HomeSubsection
            heading={selectedSection.heading}
            title={selectedSection.title}
            descriptions={selectedSection.descriptions}
          >
            {selectedSection.background && (
              <img
                src={selectedSection.background}
                className={classes.image}
                alt=""
              />
            )}
          </HomeSubsection>
        )}
      </div>
    </div>
  );
};

export default EvidenceCollectionDesktop;
