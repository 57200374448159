/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { lanIds } from "./Languages";

// Country Ids,
// To add new codes go to
// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
export const countryIds = {
  uk: "GB",
};

export const countryLan = {
  [countryIds.uk]: lanIds.en,
};

const loadImage = (countryId, id) => {
  const img = require(`../assets/supportLines/${countryId}/${id}`);
  return img;
};

export const countryData = {
  supportLines: {
    [countryIds.uk]: [
      {
        title: "Help after crime",
        description:
          "If you’ve been affected by crime, we can give you the support you need to move forward. Our services are free, confidential and available to anyone in England and Wales, regardless of whether the crime has been reported or how long ago it happened. Choose from a number of ways to contact us.",
        buttonText: "08 08 16 89 111",
        buttonLink: "08 08 16 89 111",
        iconLink: "https://www.victimsupport.org.uk/",
        icon: loadImage(countryIds.uk, "VictimSupport.png"),
      },
      {
        title: "Refuge For Women and Children against domestic violence",
        description:
          "Freephone 24-Hour National Domestic Abuse Helpline: 0808 2000 247.",
        buttonText: "0808 2000 247",
        buttonLink: "0808 2000 247",
        iconLink: "https://www.refuge.org.uk/",
        icon: loadImage(countryIds.uk, "refuge.png"),
      },
      {
        title: "Childline",
        description:
          "You can contact Childline about anything. Whatever your worry, it’s better out than in. We’re here to support you. There are lots of different ways to speak to a Childline counsellor or get support from other young people.",
        buttonText: "0800 1111",
        buttonLink: "0800 1111",
        iconLink: "https://www.childline.org.uk/",
        icon: loadImage(countryIds.uk, "childline.png"),
      },
      {
        title: "Together we can end domestic abuse",
        description:
          "Respect is a pioneering UK domestic abuse organisation leading the development of safe, effective work with perpetrators, male victims and young people using violence in their close relationships.",
        buttonText: "0808 8010327",
        buttonLink: "0808 8010327",
        iconLink: "https://www.respect.uk.net/",
        icon: loadImage(countryIds.uk, "respect.png"),
      },
      {
        title: "Mind For Better Mental Health",
        description:
          "www.mind.org.uk. Mind is a mental health charity in England and Wales.",
        buttonText: "I need urgent help",
        buttonLink: "https://www.mind.org.uk",
        iconLink: "https://www.mind.org.uk",
        icon: loadImage(countryIds.uk, "mind.png"),
        isExternal: true,
      },
      {
        title: "Samaritans Emergency Appeal",
        description:
          "Whatever you’re going through, a Samaritan will face it with you. We’re here 24 hours a day, 365 days a year. We’re waiting for your call. Call us for free on 116 123.",
        buttonText: "116 123",
        buttonLink: "116 123",
        iconLink: "https://www.samaritans.org/",
        icon: loadImage(countryIds.uk, "samaritans.png"),
      },
      {
        title: "The Crown Prosecution Service",
        description:
          "The Crown Prosecution Service (CPS) prosecutes criminal cases that have been investigated by the police and other investigative organisations in England and Wales.",
        buttonText: "Go to CPS",
        buttonLink: "https://www.cps.gov.uk/",
        iconLink: "https://www.cps.gov.uk/",
        icon: loadImage(countryIds.uk, "cps.svg"),
        isExternal: true,
      },
    ],
  },
};
