/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import parsePhoneNumber from "libphonenumber-js";
import Text, { types } from "../Text/Text";
import styles from "../../config/styles";
import colors from "../../config/colors";

// Assets
import phone from "../../assets/backgrounds/phone.svg";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "25%",
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    minWidth: 320,
    [theme.breakpoints.down("xs")]: {
      minWidth: "90%"
    }
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "center"
  },
  icon: {
    ...styles.mixins.icon,
    backgroundImage: ({ icon }) => `url(${icon})`,
    width: "80%",
    height: 100,
    display: "block",
    margin: `${theme.spacing(5)}px auto`
  },
  description: {
    margin: theme.spacing(2, 0)
  },
  call: {
    textTransform: "initial",
    padding: theme.spacing(2),
    minWidth: "100%",
    border: ({ isExternal }) => (isExternal ? "" : "none"),
    backgroundColor: ({ isExternal }) =>
      isExternal ? "transparent" : theme.palette.secondary.main,
    "&.MuiButton-root:hover": {
      backgroundColor: ({ isExternal }) =>
        isExternal ? "transparent" : theme.palette.secondary.main
    }
  },
  callLink: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    minWidth: "100%"
  },
  phone: {
    ...styles.mixins.icon,
    height: 25,
    width: 25,
    marginRight: theme.spacing(2),
    backgroundImage: `url(${phone})`
  },
  callText: {
    color: ({ isExternal }) =>
      isExternal ? colors.grey : theme.palette.primary.contrastText
  }
}));

const SupportLine = ({
  className,
  children,
  title,
  description,
  buttonText,
  link,
  iconLink,
  icon,
  isExternal = false,
  ...rest
}) => {
  const classes = useStyles({ icon, isExternal });
  const linkFormatted = isExternal ? link : parsePhoneNumber(link).getURI();
  const buttonTextFormatted = isExternal
    ? buttonText
    : parsePhoneNumber(buttonText).formatNational();
  return (
    <div className={classes.root} {...rest}>
      <div className={classes.content}>
        <a
          href={iconLink}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.icon}
          aria-label="open"
        />
        <Text type={types.supportLineTitle}>{title}</Text>
        <Text
          type={types.supportLineDescription}
          className={classes.description}
        >
          {description}
        </Text>
      </div>
      <a
        href={linkFormatted}
        target={isExternal ? "_blank" : ""}
        rel="noopener noreferrer"
        className={classes.callLink}
      >
        <Button variant="outlined" className={classes.call}>
          {!isExternal && <div className={classes.phone} />}
          <Text className={classes.callText} type={types.supportLineCall}>
            {buttonTextFormatted}
          </Text>
        </Button>
      </a>
    </div>
  );
};

export default SupportLine;
