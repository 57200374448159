import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  formContainer: {
    display: "flex",
    width: "90%",
    marginTop: theme.spacing(5),
    padding: "4% 8%",
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    boxShadow: `10px 20px 100px 5px #00000029`,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginTop: theme.spacing(2),
      width: "100%",
      borderRadius: 0,
      backgroundColor: "transparent",
      boxShadow: `none`
    }
  }
}));

const FormContainer = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.formContainer} ${className}`} {...rest}>
      {children}
    </div>
  );
};

export default FormContainer;
