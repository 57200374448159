import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { language, getTextSingle } from "../languages/Languages";

// Backgrounds
import phones from "../assets/backgrounds/phone_request_inclusion.png";

// Components
import Section from "../components/Section/Section";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import styles from "../config/styles";
import RequestInclusionForm from "../components/RequestInclusionForm/RequestInclusionForm";
import Text, { types } from "../components/Text/Text";
import FormContainer from "../components/FormContainer/index";

const useStyles = makeStyles(theme => ({
  intro: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    paddingTop: theme.spacing(6)
  },
  bigIcon: {
    marginTop: theme.spacing(6)
  },
  formSection: {
    padding: theme.spacing(4)
  },
  sectionBottom: {
    padding: theme.spacing(4)
  },
  form: {
    ...styles.mixins.form,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
      boxShadow: "none",
      background: "transparent",
      padding: 0
    }
  },
  formData: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  phones: {
    ...styles.mixins.icon,
    display: "flex",
    width: "50%",
    margin: `${theme.spacing(2)}px 0px`,
    marginRight: theme.spacing(10),
    backgroundImage: `url(${phones})`,
    backgroundPosition: "top center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 330,
      margin: 0
    }
  }
}));

const RequestInclusion = () => {
  const classes = useStyles();
  // Text
  const { intro, notice } = language.pages.requestInclusion;
  const { title, description, information } = intro;
  const { title: noticeTitle, description: noticeDescription } = notice;

  return (
    <Section isMain>
      <div className={classes.intro}>
        <SectionTitle
          title={getTextSingle(title)}
          description={getTextSingle(description)}
          information={getTextSingle(information)}
        />
      </div>
      <FormContainer>
        <div className={classes.phones} />
        <RequestInclusionForm className={classes.formData} />
      </FormContainer>
      <Text type={types.noticeTitle}>{getTextSingle(noticeTitle)}</Text>
      <Text type={types.noticeDescription}>
        {getTextSingle(noticeDescription)}
      </Text>
    </Section>
  );
};

export default RequestInclusion;
