/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";

import Text, { types } from "../../components/Text/Text";
import dateUtils from "../../utils/dateUtils";
import colors from "../../config/colors";
import LinkScroll from "../../components/LinkScroll/LinkScroll";
import { getRoute, urls } from "../../config/urls";

// Backgrounds
import DownloadIcon from "../../assets/backgrounds/download.svg";
import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  intro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative"
  },
  bar: {
    height: 10,
    width: "3%",
    minWidth: 30,
    margin: theme.spacing(3, 0),
    background: colors.lightBlue,
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0),
      height: 5
    }
  },
  finishLine: {
    height: 1,
    margin: theme.spacing(5, 0),
    width: "100%",
    background: "rgba(0,0,0,0.1)",
    display: "flex",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, 0)
    }
  },
  downloadButton: {
    height: 32,
    width: 32,
    position: "absolute",
    top: 0,
    right: 0,
    borderRadius: "50%"
  },
  icon: {
    ...styles.mixins.icon,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${DownloadIcon})`,
    backgroundSize: "60%"
  },
  image: {
    maxWidth: 300,
    maxHeight: 150,
    marginBottom: theme.spacing(2)
  }
}));

const PressPreview = ({
  id,
  date,
  title,
  lead,
  pdfURL,
  imageURL,
  ...props
}) => {
  const classes = useStyles();

  const formattedDate = dateUtils(date);

  return (
    <div className={classes.intro}>
      <LinkScroll
        to={
          id &&
          `${getRoute(urls.pressReleasesWithId, {
            pressReleaseId: id
          })}#pageStart`
        }
        {...props}
      >
        <img src={imageURL} alt="" className={classes.image} />
        <Text type={types.answer}>{formattedDate}</Text>
        <Text type={types.pressPreviewTitle}>{title}</Text>
        <div className={classes.bar} />
        <Text type={types.answer}>{lead}</Text>
        <div className={classes.finishLine} />
      </LinkScroll>
      {pdfURL && (
        <ButtonBase className={classes.downloadButton}>
          <a
            className={classes.icon}
            href={pdfURL}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="pdf"
          />
        </ButtonBase>
      )}
    </div>
  );
};

export default PressPreview;
