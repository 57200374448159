import { getSupportUrl } from "../../config/Api";

export const types = {
  SEND_EMAIL_REQUEST: "SEND_EMAIL_REQUEST",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",
};

export const actions = {
  sendEmail: (email) => ({ type: types.SEND_EMAIL_REQUEST, email }),
};

const requestEmail = () => ({
  type: types.SEND_EMAIL_REQUEST,
});

export const sendContactEmail = ({ mailForm, onFinish }) => (dispatch) => {
  dispatch(requestEmail());
  fetch(getSupportUrl(), {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    },
    body: JSON.stringify(mailForm),
  })
    .then((data) => {
      onFinish(data.ok);
    })
    .catch((err) => {
      onFinish(false, err);
    });
};
