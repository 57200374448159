import Cookies from "js-cookie";

export const cookieKey = {
  cookieSettings: "cookieSettings"
};

export const cookieSettingsKey = {
  analytics: "analytics",
  functionality: "functionality",
  strictlyNecessary: "strictlyNecessary"
};

const setCookie = (key, value) => {
  const cookie = typeof value === "object" ? JSON.stringify(value) : value;
  Cookies.set(key, cookie, { expires: 30 });
};

export const getCookies = (key, isObject) => {
  const value = Cookies.get(key);
  if (isObject) {
    return value ? JSON.parse(value) : {};
  }
  return value;
};

export default setCookie;
