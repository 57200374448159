import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import colors from "../../config/colors";
import styles from "../../config/styles";

export default withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    overflow: "unset"
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: colors.mintGreen,
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: colors.mintGreen,
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: styles.values.boxShadowMedium
  },
  track: {
    borderRadius: 26 / 2,
    border: styles.values.border,
    backgroundColor: colors.mystic,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked
    }}
    {...props}
  />
));
