import React, { useEffect } from "react";
import { Provider, useSelector } from "react-redux";

// Material
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";

// Locals
import colors from "./config/colors";
import Routes from "./routes/Routes";
import store from "./state/store";
import Initialize from "./config/googleAnalitycs";
import GoogleConsentProvider from "./context/GoogleConsentProvider";

const breakpointValues = {
  xs: 0,
  sm: 600,
  md: 1200,
  lg: 1920,
  xl: 3842
};
const theme = createTheme({
  breakpoints: { values: breakpointValues },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(",")
  },
  palette: {
    common: {
      black: colors.black
    },
    primary: {
      main: colors.black,
      contrastText: colors.white
    },
    secondary: {
      main: colors.lightBlue,
      contrastText: colors.white
    },
    text: {
      primary: colors.black
    },
    background: {
      default: colors.white,
      paper: colors.lightGrey
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:before": {
          content: "none"
        }
      }
    },
    MuiAlert: {
      root: {
        opacity: 0.9
      },
      standardInfo: {
        backgroundColor: `${colors.pictonBlue}90`,
        color: colors.white,

        "& .MuiAlert-icon": {
          color: colors.white
        }
      },
      standardSuccess: {
        backgroundColor: `${colors.pictonBlue}90`,
        color: colors.white,

        "& .MuiAlert-icon": {
          color: colors.white
        }
      }
    }
  }
});

const Content = () => {
  // const { cookies: cooks, isBannerOpen } = useSelector(state => state.cookies);

  useEffect(() => {
    // Initialize();
    // if (!isBannerOpen && cooks.analytical) {
    //   Initialize();
    // }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

const App = () => (
  <Provider store={store}>
    <GoogleConsentProvider>
      <Content />
    </GoogleConsentProvider>
  </Provider>
);

export default App;
