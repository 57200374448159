import { countryIds, countryLan } from "../languages/Countries";

export const getCurrentCountry = () => {
  const country = countryIds.uk;
  return {
    countryId: country,
    lanId: countryLan[country],
  };
};

export const getCountryData = (data) => {
  const { countryId } = getCurrentCountry();
  const text = data[countryId];
  return text;
};

export const getText = (text) => {
  const { lanId } = getCurrentCountry();
  const textInLan = text[lanId];
  return textInLan;
};
