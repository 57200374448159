import React, { createContext, useContext, useEffect } from "react";

import initGTM from "../googleTagManager";

const GoogleConsentContext = createContext();

export const useGoogleConsent = () => useContext(GoogleConsentContext);

// cliente
const consentListeners = [];

console.log("create function");
window.registerConsentListener = cbk => {
  // eslint-disable-next-line no-console
  console.log("register consent listener: ", cbk);
  consentListeners.push(cbk);
};

const GoogleConsentProvider = ({ children }) => {
  useEffect(() => {
    initGTM();
  }, []);

  const dispatchConsentListeners = cookies => {
    // eslint-disable-next-line no-console
    console.log("mapping consent listeners array", {
      consentListeners,
      cookies
    });
    consentListeners.forEach(cbk => cbk(cookies));
  };

  return (
    <GoogleConsentContext.Provider
      value={{
        dispatchConsentListeners
      }}
    >
      {children}
    </GoogleConsentContext.Provider>
  );
};

export default GoogleConsentProvider;

// google listeners mock
// const googleListenersMock = [
//   register => {
//     register(cookies1 => {
//       // eslint-disable-next-line no-console
//       console.log({
//         cookies1
//       });
//     });
//   },
//   register => {
//     register(cookies2 => {
//       // eslint-disable-next-line no-console
//       console.log({
//         cookies2
//       });
//     });
//   }
// ];

// googleListenersMock.forEach(cbk => {
//   cbk(window.registerConsentListener);
// });
