import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

// Components
import CookieSwitch from "./CookieSwitch";
import Section from "../../components/Section/Section";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Tac from "../../components/TermsAndConditions/TermsAndConditions";
import Text, { types } from "../../components/Text/Text";

// Utils
import { getText } from "../../utils/textUtils";
import setCookie, { cookieKey } from "../../utils/cookieManager";

import { actions } from "../../state/Cookies/actions";
import { language, getTextSingle } from "../../languages/Languages";
import { getTermsAndConditions } from "../../state/Terms/actions";

const useStyles = makeStyles(theme => ({
  intro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative"
  },
  contents: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    paddingTop: theme.spacing(6)
  },
  termsPolicy: {
    width: "100%",
    maxWidth: "768px"
  },
  tableOfContents: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginRight: theme.spacing(4),
    minWidth: 270,
    [theme.breakpoints.down("md")]: {
      minWidth: 170
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  tableElement: {
    fontWeight: "bold",
    fontSize: 18,
    marginBottom: theme.spacing(4)
  },
  settingsTitle: {
    marginBottom: theme.spacing(2)
  }
}));

const HowToCapturePage = () => {
  const classes = useStyles();
  const { cookies: cooks } = useSelector(state => state.cookies);
  const { analytical, functionality, targeting } = cooks;
  const dispatch = useDispatch();
  const { cookiesData, isLoadingCookies, errorCookies } = useSelector(
    state => state.terms
  );

  // Text
  const { title, permissions } = language.pages.cookiePolicy;
  const { title: permTitle, cookies } = permissions;

  const updateCookies = (attribute, newValue) => {
    setCookie(cookieKey.cookieSettings, { ...cooks, [attribute]: newValue });
    dispatch(actions.setCookies({ [attribute]: newValue }));
  };

  useEffect(() => {
    const countryId = "GB";
    if (!cookiesData && !isLoadingCookies && !errorCookies) {
      dispatch(
        getTermsAndConditions({
          countryId,
          agreementType: "CookiePolicy"
        })
      );
    }
  }, [cookiesData, isLoadingCookies, dispatch, errorCookies]);

  return (
    <Section isMain>
      <div className={classes.intro}>
        <SectionTitle title={getTextSingle(title)} />
        <div className={classes.contents}>
          <div className={classes.termsPolicy}>
            <Tac
              id="termsAndConditions"
              title={getTextSingle(title)}
              text={cookiesData || errorCookies}
              isLoading={isLoadingCookies}
            >
              <form id="#cookieSettings">
                <Text
                  className={classes.settingsTitle}
                  type={types.termsElementTitle}
                >
                  {getText(permTitle)}
                </Text>
                <CookieSwitch
                  title={getText(cookies.necessary.title)}
                  description={getText(cookies.necessary.description)}
                  checked
                />
                <CookieSwitch
                  title={getText(cookies.analytical.title)}
                  description={getText(cookies.analytical.description)}
                  checked={analytical}
                  onChange={({ target }) =>
                    updateCookies("analytical", target.checked)
                  }
                />
                <CookieSwitch
                  title={getText(cookies.functionality.title)}
                  description={getText(cookies.functionality.description)}
                  checked={functionality}
                  onChange={({ target }) =>
                    updateCookies("functionality", target.checked)
                  }
                />
                <CookieSwitch
                  title={getText(cookies.targeting.title)}
                  description={getText(cookies.targeting.description)}
                  checked={targeting}
                  onChange={({ target }) =>
                    updateCookies("targeting", target.checked)
                  }
                />
              </form>
            </Tac>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HowToCapturePage;
