import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Config
import { urls, getRoute } from "../../config/urls";

// Components
import Text, { types } from "../../components/Text/Text";
import LinkScroll from "../../components/LinkScroll/LinkScroll";

// Utils
import { language } from "../../languages/Languages";
import { getText } from "../../utils/textUtils";

const useStyles = makeStyles(theme => ({
  contentElement: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    textAlign: "left",
    marginBottom: theme.spacing(2),
    "&:focus": {
      outline: "none"
    }
  },
  selected: {
    color: theme.palette.secondary.main,
    fontWeight: "bold"
  },
  subContent: {
    display: "flex",
    flexDirection: "column"
  },
  subElement: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    margin: `${theme.spacing(1)}px 0`,
    "&.selected": {
      backgroundColor: theme.palette.background.paper
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(3)
    }
  }
}));

const TableElement = ({ title, selected, userType, ...rest }) => {
  const classes = useStyles({ selected });
  const selectClass = selected ? classes.selected : "";
  // Terms and Conditions
  const termsSelected = window.location.hash === "#termsAndConditions";
  const selectTermsClass = termsSelected ? "selected" : "";
  const privacyPolicySelected = window.location.hash === "#policies";
  const privacySelected = privacyPolicySelected ? "selected" : "";
  const { termsAndConditions: toUrl } = urls;
  // Texts
  const { title: termsTitle } = language.pages.termsAndConditions.intro;
  const { title: privacyTitle } = language.pages.termsAndConditions.privacy;
  return (
    <div type="button" className={classes.contentElement} {...rest}>
      <Text className={selectClass} type={types.answer}>
        {title}
      </Text>
      {selected && (
        <div className={classes.subContent}>
          <LinkScroll
            key="link-1"
            className={`${classes.subElement} ${selectTermsClass}`}
            to={`${getRoute(toUrl, { userType })}#termsAndConditions`}
          >
            <Text type={types.tableSubElement}>{getText(termsTitle)}</Text>
          </LinkScroll>
          <LinkScroll
            key="link-2"
            className={`${classes.subElement} ${privacySelected}`}
            to={`${getRoute(toUrl, { userType })}#policies`}
          >
            <Text type={types.tableSubElement}>{getText(privacyTitle)}</Text>
          </LinkScroll>
        </div>
      )}
    </div>
  );
};

export default TableElement;
