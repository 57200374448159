import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import styles from "../../config/styles";

const useStyles = makeStyles(() => ({
  root: {
    ...styles.mixins.mainSection,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    paddingTop: 0
  },
  logo: {
    position: "absolute",
    height: 40,
    opacity: 0.8
  }
}));

const LoadingCircle = ({ size, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...props}>
      <CircularProgress size={size || 130} thickness={1} />
      {/* <img className={classes.logo} src={mainLogo} alt="" /> Waiting for white logo */}
    </div>
  );
};

export default LoadingCircle;
