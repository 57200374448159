import { getLegalAgreementsUrl } from "../../config/Api";

export const types = {
  TERMS_AND_CONDITIONS_REQUEST: "TERMS_AND_CONDITIONS_REQUEST",
  TERMS_AND_CONDITIONS_SUCCESS: "TERMS_AND_CONDITIONS_SUCCESS",
  TERMS_AND_CONDITIONS_FAIL: "TERMS_AND_CONDITIONS_FAIL",
  PRIVACY_REQUEST: "PRIVACY_REQUEST",
  PRIVACY_SUCCESS: "PRIVACY_SUCCESS",
  PRIVACY_FAIL: "PRIVACY_FAIL",
  COOKIES_REQUEST: "COOKIES_REQUEST",
  COOKIES_SUCCESS: "COOKIES_SUCCESS",
  COOKIES_FAIL: "COOKIES_FAIL"
};

// Terms and conditions
const requestTermsAndConditions = () => ({
  type: types.TERMS_AND_CONDITIONS_REQUEST
});
const requestTermsSuccess = payload => ({
  type: types.TERMS_AND_CONDITIONS_SUCCESS,
  payload
});
const requestTermsFail = payload => ({
  type: types.TERMS_AND_CONDITIONS_FAIL,
  payload
});

// Privacy
const requestPrivacy = () => ({
  type: types.PRIVACY_REQUEST
});
const requestPrivacySuccess = payload => ({
  type: types.PRIVACY_SUCCESS,
  payload
});
const requestPrivacyFail = payload => ({
  type: types.PRIVACY_FAIL,
  payload
});

// Cookies
const requestCookiesPolicy = () => ({
  type: types.COOKIES_REQUEST
});
const requestCookiesPolicySuccess = payload => ({
  type: types.COOKIES_SUCCESS,
  payload
});
const requestCookiesPolicyFail = payload => ({
  type: types.COOKIES_FAIL,
  payload
});

const agreementTypes = {
  PrivacyPolicy: {
    request: requestPrivacy,
    success: requestPrivacySuccess,
    fail: requestPrivacyFail
  },
  CookiePolicy: {
    request: requestCookiesPolicy,
    success: requestCookiesPolicySuccess,
    fail: requestCookiesPolicyFail
  },
  default: {
    request: requestTermsAndConditions,
    success: requestTermsSuccess,
    fail: requestTermsFail
  }
};

export const getTermsAndConditions =
  ({ countryId, userType, agreementType, format = "Html" }) =>
  dispatch => {
    const { request, success, fail } =
      agreementTypes[agreementType] || agreementTypes.default;

    dispatch(request());
    fetch(
      getLegalAgreementsUrl({ countryId, userType, agreementType, format }),
      {
        method: "GET",
        headers: {
          accept: "application/json"
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        dispatch(success(data));
      })
      .catch(({ message }) => {
        dispatch(fail(message));
      });
  };
