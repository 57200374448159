/* eslint-disable react/no-danger */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

// Components
import Section from "../../components/Section/Section";

import { getPressReleaseId } from "../../state/PressRelease/actions";
import LoadingCircle from "../../components/LoadingCircle/LoadingCircle";
import Text, { types } from "../../components/Text/Text";
import colors from "../../config/colors";

// Backgrounds
import DownloadIcon from "../../assets/backgrounds/download.svg";
import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  content: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    fontSize: 17
  },
  loginStyleContainer: {
    display: "flex",
    alignSelf: "flex-end",
    marginTop: theme.spacing(5)
  },
  loginStyle: {
    padding: theme.spacing(1, 6),
    borderRadius: 40,
    fontSize: 17,
    textTransform: "none",
    boxShadow: "none",
    backgroundColor: colors.lightBlue,
    "&:hover": {
      backgroundColor: colors.lightBlue
    },
    color: colors.white
  },
  icon: {
    ...styles.mixins.icon,
    width: "1em",
    height: "1em",
    backgroundImage: `url(${DownloadIcon})`,
    backgroundSize: "60%"
  },
  pressImage: {
    width: "100%",
    marginBottom: theme.spacing(2)
  }
}));

const getHtmlContent = htmlString => {
  if (!htmlString) return "";
  const content = <span dangerouslySetInnerHTML={{ __html: htmlString }} />;
  return content;
};

const PressRelease = () => {
  const classes = useStyles();
  const { pressReleaseId } = useParams();
  const dispatch = useDispatch();
  // Press Release
  const { loading, pressRelease } = useSelector(state => state.pressReleases);

  useEffect(() => {
    dispatch(getPressReleaseId({ pressReleaseId }));
  }, [pressReleaseId, dispatch]);

  const content = pressRelease ? (
    <div className={classes.content}>
      <Text type={types.pressTitle}>{pressRelease && pressRelease.title}</Text>
      <Text type={types.pressDescription}>
        {pressRelease && pressRelease.lead}
      </Text>
      {pressRelease.imageURL && (
        <img
          className={classes.pressImage}
          src={pressRelease.imageURL}
          alt=""
        />
      )}
      {getHtmlContent(pressRelease.body)}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={pressRelease.pdfURL}
        className={classes.loginStyleContainer}
      >
        <Button className={classes.loginStyle} variant="contained">
          DOWNLOAD PDF VERSION
        </Button>
      </a>
    </div>
  ) : (
    <Text> Could not connect to server.</Text>
  );

  return <Section isMain>{loading ? <LoadingCircle /> : content}</Section>;
};

export default PressRelease;
