import React, { useEffect, useState } from "react";

// Material
import { makeStyles } from "@material-ui/styles";

// Config
import { getFaqsUrl } from "../config/Api";
import { language, getTextSingle } from "../languages/Languages";

// Components
import CantFind from "../components/CantFind/CantFind";
import LoadingCircle from "../components/LoadingCircle/LoadingCircle";
import QuestionAnswear from "../components/QuestionAnswer/QuestionAnswer";
import Section from "../components/Section/Section";
import SectionTitle from "../components/SectionTitle/SectionTitle";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "80vh",
    padding: theme.spacing(10, 0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const Faqs = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  // Text
  const { intro } = language.pages.faq;
  const { title, description } = intro;

  useEffect(async () => {
    const response = await fetch(getFaqsUrl);
    if (response && response.ok) {
      const faqs = await response.json();
      setData(faqs);
    }
    setLoading(false);
  }, []);

  return (
    <Section isMain>
      <SectionTitle
        title={getTextSingle(title)}
        description={getTextSingle(description)}
      />
      {loading && (
        <Section isMain className={classes.root}>
          <LoadingCircle />
        </Section>
      )}
      {data &&
        data.map(item => (
          <QuestionAnswear
            key={item.question}
            title={item.question}
            description={item.answer}
          />
        ))}
      <CantFind />
    </Section>
  );
};

export default Faqs;
