/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Text, { types } from "../Text/Text";

// Config
import styles from "../../config/styles";

// Backgrounds
import Arrow from "../../assets/backgrounds/arrow.svg";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    padding: "2.5vw 8vw",
    minWidth: 400,
    borderRadius: 10
  },
  rootSelected: {},
  arrow: {
    ...styles.mixins.icon,
    backgroundImage: `url(${Arrow})`,
    display: "flex",
    minWidth: "1.5em",
    marginLeft: theme.spacing(2)
  },
  title: {
    margin: `${theme.spacing(2)}px 0`,
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    width: "100%"
  },
  titleDescription: {
    width: "95%",
    paddingLeft: "5%",
    margin: theme.spacing(2, 0),
    whiteSpace: "pre-line",
    display: ({ selected }) => !selected && "none"
  },
  bottomLine: {
    width: "68%",
    height: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const QuestionAnswear = ({
  className,
  children,
  title,
  description,
  ...rest
}) => {
  const [selected, setSelected] = useState(false);
  const classes = useStyles({ selected });
  const baseClassName =
    classes.root + (selected ? ` ${classes.rootSelected}` : "");
  return (
    <>
      <div className={`${baseClassName} ${className}`} {...rest}>
        <div className={classes.title} onClick={() => setSelected(!selected)}>
          <Text type={types.question} component="h2">
            {title}
          </Text>
          <div className={classes.arrow} />
        </div>
        <Text
          className={classes.titleDescription}
          type={types.answer}
          component="p"
        >
          {description}
        </Text>
      </div>
      <div className={classes.bottomLine} />
    </>
  );
};

export default QuestionAnswear;
