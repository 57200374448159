import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Text, { types } from "../Text/Text";
import LinkScroll from "../LinkScroll/LinkScroll";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    width: ({ width }) => `calc(${width || "100%"} - 8px)`,
    margin: "24px 0px",
    [theme.breakpoints.down("xs")]: {
      border: `none`
    }
  },
  link: {
    width: "100%",
    height: 60,
    textTransform: "none",
    borderRadius: theme.spacing(1.5),
    fontSize: 17,
    fontFamily: "Maven Pro"
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%"
  }
}));

const RequestBox = ({
  title,
  description,
  buttonText,
  buttonLink,
  width,
  className,
  ...props
}) => {
  const classes = useStyles({ width });

  return (
    <div {...props} className={[className, classes.root].join(" ")}>
      <div className={classes.titleContainer}>
        <Text type={types.requestBoxTitle}>{title}</Text>
        <Text type={types.requestBoxDescription}>{description}</Text>
      </div>
      <LinkScroll className={classes.link} to={buttonLink}>
        <Button className={classes.link} color="primary" variant="contained">
          <b>{buttonText}</b>
        </Button>
      </LinkScroll>
    </div>
  );
};

export default RequestBox;
