import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core/index";

// Components
import Section from "../components/Section/Section";
import { language, getTextSingle } from "../languages/Languages";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import Text from "../components/Text/Text";
import SupportLine from "../components/SupportLine/SupportLine";
import LoadingCircle from "../components/LoadingCircle/LoadingCircle";
import useEmergencyContacts from "../hooks/useEmergencyContacts";
import RequestBox from "../components/RequestBox/RequestBox";
import url, { getRoute } from "../config/urls";

const useStyles = makeStyles(theme => ({
  bigIcon: {
    marginTop: theme.spacing(5)
  },
  supportLines: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    marginBottom: theme.spacing(10)
  },
  noDataText: {
    textAlign: "center",
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  requestBoxList: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center"
    }
  }
}));

const VictimSupport = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));
  // Text
  const { intro, requestBox } = language.pages.victimSupport;
  const { title, description } = intro;
  const { organization, raiseAwareness, cantFind } = requestBox.requests;

  // Reducer Data
  const { supportLines, loading } = useEmergencyContacts({
    askForLocation: true
  });

  const renderSupportLines = () => {
    const isEmpty = supportLines && supportLines.length === 0;
    return supportLines && !isEmpty ? (
      supportLines.map(supportLine => {
        const isNumber = supportLine.phoneNumber;
        return (
          <SupportLine
            key={supportLine.name}
            title={supportLine.name}
            description={supportLine.description}
            buttonText={supportLine.phoneNumber || supportLine.callToAction}
            link={
              supportLine.phoneNumber ||
              supportLine.documentURL ||
              supportLine.url
            }
            iconLink={supportLine.url}
            icon={supportLine.imageURLs.full}
            isExternal={!isNumber}
          />
        );
      })
    ) : (
      <Text className={classes.noDataText}>
        {isEmpty
          ? "We're really sorry but we do not currently have any links to support services in your region."
          : "Please enable browsing location."}
      </Text>
    );
  };

  return (
    <Section isMain>
      <SectionTitle
        title={getTextSingle(title)}
        description={getTextSingle(description)}
      />
      <div className={classes.supportLines}>
        {loading ? <LoadingCircle /> : renderSupportLines()}
      </div>
      <div className={classes.requestBoxList}>
        <RequestBox
          title={getTextSingle(organization.title)}
          description={getTextSingle(organization.description)}
          buttonText={getTextSingle(organization.button)}
          buttonLink={`${getRoute(url.requestInclusion)}#pageStart`}
          width={isMobile ? "100%" : "33%"}
        />
        <RequestBox
          title={getTextSingle(raiseAwareness.title)}
          description={getTextSingle(raiseAwareness.description)}
          buttonText={getTextSingle(raiseAwareness.button)}
          buttonLink={`${getRoute(url.promotionalMaterial)}#pageStart`}
          width={isMobile ? "100%" : "33%"}
        />
        <RequestBox
          title={getTextSingle(cantFind.title)}
          description={getTextSingle(cantFind.description)}
          buttonText={getTextSingle(cantFind.button)}
          buttonLink={`${getRoute(url.contactSupport)}#pageStart`}
          width={isMobile ? "100%" : "33%"}
        />
      </div>
    </Section>
  );
};

export default VictimSupport;
