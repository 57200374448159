import { useState } from "react";

const useSelectTransition = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [transitioning, setTransitioning] = useState(false);

  const setIndex = index => {
    setTransitioning(true);
    setSelectedItem(index);
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(() => {
        setSelectedIndex(index);
        setTransitioning(false);
      }, 500)
    );
  };

  return { selectedItem, selectedIndex, transitioning, setIndex };
};

export default useSelectTransition;
