import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

// Components
import Section from "../../components/Section/Section";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import LoadingCircle from "../../components/LoadingCircle/LoadingCircle";
import PressPreview from "./PressPreview";

import { language } from "../../languages/Languages";
import { getText } from "../../utils/textUtils";
import Text from "../../components/Text/Text";
import { getPressReleases } from "../../state/PressRelease/actions";

const useStyles = makeStyles(theme => ({
  intro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative"
  },
  pressContent: {
    display: "flex",
    margin: theme.spacing(3, 0)
  },
  pressList: {
    display: "flex",
    flexDirection: "column"
  }
}));

const PressRelease = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // Press Release
  const { loadingList, pressReleasesList } = useSelector(
    state => state.pressReleases
  );

  useEffect(() => {
    dispatch(getPressReleases());
  }, [dispatch]);

  const { title: titleText } = language.pages.pressRelease;

  const data = pressReleasesList ? (
    pressReleasesList.map(({ id, title, lead, dateTime, pdfURL }) => (
      <PressPreview
        id={id}
        key={id}
        title={title}
        lead={lead}
        date={dateTime}
        pdfURL={pdfURL}
      />
    ))
  ) : (
    <Text>Server not available at the moment.</Text>
  );

  return (
    <Section isMain>
      <SectionTitle title={getText(titleText)} />
      <div className={classes.pressContent}>
        <div className={classes.pressList}>
          {loadingList ? <LoadingCircle /> : data}
        </div>
      </div>
    </Section>
  );
};

export default PressRelease;
