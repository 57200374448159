import React from "react";

// Material
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

// Config
import styles from "../../config/styles";

// Background
import bsiLogo from "../../assets/badges/en/BSI logo.png";
import kulpaLogo from "../../assets/logos/kulpa-logo.png";

// Config
import { getTextSingle, language } from "../../languages/Languages";
import { urls, getRoute } from "../../config/urls";
import { getText } from "../../utils/textUtils";
import { userTypes } from "../../utils/UserTypes";

// Components
import LinkScroll from "../LinkScroll/LinkScroll";
import LoginButton from "../LoginButton/LoginButton";
import SocialLinks from "../SocialLinks/SocialLinks";
import Text, { types } from "../Text/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "relative",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(2)
    }
  },
  columns: {
    display: "flex",
    width: "100%",
    justifyContent: "space-evenly",
    background: `white`,
    padding: `${styles.values.footerHeight * 0.1}px ${
      styles.values.standardMargin
    } ${theme.spacing(10)}px ${styles.values.standardMargin}`,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(5),
      paddingTop: theme.spacing(8),
      flexDirection: "column",
      alignItems: "center"
    }
  },
  firstColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginRight: theme.spacing(2),
    maxWidth: 250,
    [theme.breakpoints.down("sm")]: {
      margin: 0,
      marginBottom: theme.spacing(2),
      alignItems: "center"
    }
  },
  column: {
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  columnSide: {
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      alignItems: "center"
    }
  },
  columnPlatform: {
    display: "flex",
    flexDirection: "column",
    width: 200,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      width: 165
    }
  },
  columnFinal: {
    display: "flex"
  },
  badeContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: 0
    }
  },
  hideMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  columntext: {
    marginBottom: theme.spacing(4)
  },
  title: {
    marginBottom: theme.spacing(5),
    textTransform: "uppercase",
    fontWeight: "bold",
    opacity: 0.5
  },
  titleSide: {
    marginBottom: theme.spacing(3),
    textTransform: "uppercase",
    fontWeight: "bold",
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  appIcon: {
    ...styles.mixins.icon,
    backgroundImage: `url(${kulpaLogo})`,
    width: 200,
    height: 47,
    fontFamily: "Monsterrat",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      width: 126,
      height: 28
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      marginBottom: theme.spacing(2)
    }
  },
  badgeIcon: {
    width: 120,
    height: 70,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: 80,
      height: 47
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0
    }
  },
  copywright: {
    margin: theme.spacing(2, 0),
    textAlign: "right",
    fontSize: 12,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center"
    }
  },
  loginButton: {
    fontSize: 17,
    [theme.breakpoints.down("md")]: {
      width: 100,
      height: 38,
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  justDesktop: {
    fontSize: 14
  }
}));

const Footer = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));
  const { victimSupport, news, tech, legal, platform, appsInfo } =
    language.footer;
  const { designProtectionA, designProtectionB, designProtectionC } = appsInfo;
  // Column 2
  const { support } = victimSupport;
  const { pressRelease } = news;
  // Column 3
  const { title: titleTech, issue, faq } = tech;
  const { title: cookieTitle } = language.pages.cookiePolicy;
  const {
    title: titleLegal,
    termsAndConditions,
    privacyPolicy: PrivacyPolicy
  } = legal;
  // Column 4
  const { title, copywright } = platform;

  return (
    <footer className={classes.root}>
      <div className={classes.columns}>
        <div className={classes.columnSide}>
          <div className={classes.firstColumn}>
            <div className={classes.appIcon} />
            {!isMobile && (
              <div>
                <Text type={types.smaller} className={classes.justDesktop}>
                  {getText(designProtectionA)}
                </Text>
                <Text type={types.smaller} className={classes.justDesktop}>
                  {getText(designProtectionB)}
                </Text>
                <Text type={types.smaller} className={classes.justDesktop}>
                  {getText(designProtectionC)}
                </Text>
                <div className={classes.badeContainer}>
                  <img
                    className={classes.badgeIcon}
                    src={bsiLogo}
                    alt="plate"
                  />
                </div>
              </div>
            )}
          </div>
          {isMobile && (
            <>
              <SocialLinks />
              <Text className={classes.copywright} type={types.small}>
                {getTextSingle(copywright)}
              </Text>
            </>
          )}
        </div>
        <div className={classes.column}>
          <Text className={classes.title} type={types.small}>
            {getTextSingle(titleLegal)}
          </Text>
          <LinkScroll
            to={`${getRoute(urls.termsAndConditions, {
              userType: userTypes.relatives
            })}#pageStart`}
          >
            <Text className={classes.columntext} type={types.small}>
              {getTextSingle(termsAndConditions)}
            </Text>
          </LinkScroll>
          <LinkScroll
            to={`${getRoute(urls.termsAndConditions, {
              userType: userTypes.relatives
            })}#policies`}
          >
            <Text className={classes.columntext} type={types.small}>
              {getTextSingle(PrivacyPolicy)}
            </Text>
          </LinkScroll>
          <LinkScroll to={`${getRoute(urls.cookie)}#pageStart`}>
            <Text className={classes.columntext} type={types.small}>
              {getTextSingle(cookieTitle)}
            </Text>
          </LinkScroll>
          <LinkScroll to={`${getRoute(urls.pressReleases)}#pageStart`}>
            <Text className={classes.columntext} type={types.small}>
              {getTextSingle(pressRelease)}
            </Text>
          </LinkScroll>
        </div>
        <div className={classes.column}>
          <Text className={classes.title} type={types.small}>
            {getTextSingle(titleTech)}
          </Text>
          <LinkScroll to={`${urls.faqs}#pageStart`}>
            <Text className={classes.columntext} type={types.small}>
              {getTextSingle(faq)}
            </Text>
          </LinkScroll>
          <LinkScroll to={`${urls.victimSupport}#pageStart`}>
            <Text className={classes.columntext} type={types.small}>
              {getTextSingle(support)}
            </Text>
          </LinkScroll>
          <LinkScroll to={`${urls.contactSupport}#pageStart`}>
            <Text className={classes.columntext} type={types.small}>
              {getTextSingle(issue)}
            </Text>
          </LinkScroll>
        </div>
        <div className={classes.columnFinal}>
          <div className={`${classes.hideMobile} ${classes.column}`}>
            <div className={classes.columnPlatform}>
              <Text className={classes.titleSide} type={types.small}>
                {getTextSingle(title)}
              </Text>
              <LoginButton className={classes.loginButton} />
            </div>
          </div>
          <div className={`${classes.hideMobile} ${classes.columnSide}`}>
            <SocialLinks />
            <Text className={classes.copywright} type={types.small}>
              {getTextSingle(copywright)}
            </Text>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
