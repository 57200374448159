import { combineReducers } from "redux";
import constant from "lodash-es/constant";
import createReducer from "../../utils/createReducer";

import { types } from "./actions";

const {
  CERTIFICATES_LIST_REQUEST,
  CERTIFICATES_LIST_SUCCESS,
  CERTIFICATES_LIST_FAIL,
  CERTIFICATES_REQUEST,
  CERTIFICATES_SUCCESS,
  CERTIFICATES_FAIL
} = types;

const certificationsList = createReducer(null, {
  [CERTIFICATES_LIST_SUCCESS]: (state, { payload }) => payload,
});

const certification = createReducer(null, {
  [CERTIFICATES_SUCCESS]: (state, { payload }) => payload,
});

const loadinglist = createReducer(false, {
  [CERTIFICATES_LIST_REQUEST]: constant(true),
  [CERTIFICATES_LIST_SUCCESS]: constant(false),
  [CERTIFICATES_LIST_FAIL]: constant(false),
});

const loading = createReducer(false, {
  [CERTIFICATES_REQUEST]: constant(true),
  [CERTIFICATES_SUCCESS]: constant(false),
  [CERTIFICATES_FAIL]: constant(false),
});


export default combineReducers({
  certificationsList,
  loadinglist,
  certification,
  loading,
});
