import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Components
import Text from "../../components/Text/Text";
import Switch from "../../components/Switch/Switch";

const useStyles = makeStyles(theme => ({
  contentElement: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2)
    }
  },
  title: {
    margin: theme.spacing(1, 0),
    fontSize: 20,
    fontFamily: "Gibson",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 17
    }
  },
  description: {
    fontSize: 17,
    fontFamily: "Gibson",
    [theme.breakpoints.down("xs")]: {
      fontSize: 17
    }
  }
}));

const CookieSwitch = ({ title, description, ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.contentElement}>
      <Switch {...rest} />
      <div>
        <Text className={classes.title}>{title}</Text>
        <Text className={classes.description}>{description}</Text>
      </div>
    </div>
  );
};

export default CookieSwitch;
