import { getCertificatesUrl, getCertificateIdUrl } from "../../config/Api";

export const types = {
  CERTIFICATES_LIST_REQUEST: "CERTIFICATES_LIST_REQUEST",
  CERTIFICATES_LIST_SUCCESS: "CERTIFICATES_LIST_SUCCESS",
  CERTIFICATES_LIST_FAIL: "CERTIFICATES_LIST_FAIL",
  CERTIFICATES_REQUEST: "CERTIFICATES_REQUEST",
  CERTIFICATES_SUCCESS: "CERTIFICATES_SUCCESS",
  CERTIFICATES_FAIL: "CERTIFICATES_FAIL",
};

// Certificates
const certificatesListRequest = () => ({
  type: types.CERTIFICATES_LIST_REQUEST,
});
const certificatesListSuccess = (payload) => ({
  type: types.CERTIFICATES_LIST_SUCCESS,
  payload,
});
const certificatesListFailure = (payload) => ({
  type: types.CERTIFICATES_LIST_FAIL,
  payload,
});

const certificateRequest = () => ({
  type: types.CERTIFICATES_REQUEST,
});
const certificateSuccess = (payload) => ({
  type: types.CERTIFICATES_SUCCESS,
  payload,
});
const certificateFailure = (payload) => ({
  type: types.CERTIFICATES_FAIL,
  payload,
});

export const getCertificates = () => (dispatch) => {
  dispatch(certificatesListRequest())
  fetch(getCertificatesUrl(), {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(certificatesListSuccess(data));
    })
    .catch(({ message }) => {
      dispatch(certificatesListFailure(message));
    });
};

export const getCertificateById = ({ id }) => (dispatch) => {
  dispatch(certificateRequest())
  fetch(getCertificateIdUrl(id), {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(certificateSuccess(data));
    })
    .catch(({ message }) => {
      dispatch(certificateFailure(message));
    });
};
