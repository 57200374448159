import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core/index";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Icons
import CheckIcon from "@material-ui/icons/Check";

// Languages
import { language, getTextSingle } from "../../languages/Languages";
import FormInput from "../ContactForm/FormInput";
import colors from "../../config/colors";
import { sendInclusionRequestEmail } from "../../state/PromotionalMaterial/actions";
import fromFileToBase64 from "../../utils/formatUtils";
import Text, { types } from "../Text/Text";
import { getText } from "../../utils/textUtils";
import useOnErrorScroll from "../../hooks/useOnErrorScroll";

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    position: "relative"
  },
  bigIcon: {
    marginTop: theme.spacing(6)
  },
  formSection: {
    padding: theme.spacing(4)
  },
  field: {
    marginBottom: `${theme.spacing(1)}px`
  },
  submit: {
    color: colors.white,
    width: "6vh",
    minWidth: 160,
    padding: theme.spacing(1, 5),
    borderRadius: 40,
    border: "none",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.6vw"
    },
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "space-evenly"
    },
    "&.Mui-disabled": {
      color: colors.black,
      background: colors.grey
    }
  }
}));

const inputNames = {
  logoImage: "logoImage",
  organizationName: "organizationName",
  shortDescription: "shortDescription",
  longDescription: "longDescription",
  website: "website",
  phoneNumber: "phoneNumber",
  emailAddress: "emailAddress",
  regionsSupported: "regionsSupported",
  givenName: "givenName",
  familyName: "familyName",
  jobTitle: "jobTitle"
};

const inputNamesListOrdered = [
  inputNames.logoImage,
  inputNames.organizationName,
  inputNames.shortDescription,
  inputNames.longDescription,
  inputNames.website,
  inputNames.emailAddress,
  inputNames.phoneNumber,
  inputNames.regionsSupported,
  inputNames.givenName,
  inputNames.familyName,
  inputNames.jobTitle
];

const formSchema = Yup.object().shape({
  [inputNames.logoImage]: Yup.object().required(
    getTextSingle(language.formGeneric.errors.required)
  ),
  [inputNames.givenName]: Yup.string()
    .max(30, getTextSingle(language.formGeneric.errors.tooLong))
    .required(getTextSingle(language.formGeneric.errors.required)),
  [inputNames.familyName]: Yup.string()
    .max(30, getTextSingle(language.formGeneric.errors.tooLong))
    .required(getTextSingle(language.formGeneric.errors.required)),
  [inputNames.organizationName]: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  ),
  [inputNames.website]: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  ),
  [inputNames.emailAddress]: Yup.string()
    .email(getTextSingle(language.formGeneric.errors.invalidEmail))
    .required(getTextSingle(language.formGeneric.errors.required)),
  [inputNames.phoneNumber]: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  ),
  [inputNames.shortDescription]: Yup.string()
    .max(140, getTextSingle(language.formGeneric.errors.tooLong))
    .required(getTextSingle(language.formGeneric.errors.required)),
  [inputNames.longDescription]: Yup.string()
    .max(140 * 3, getTextSingle(language.formGeneric.errors.tooLong))
    .required(getTextSingle(language.formGeneric.errors.required)),
  [inputNames.regionsSupported]: Yup.string()
    .max(140 * 3, getTextSingle(language.formGeneric.errors.tooLong))
    .required(getTextSingle(language.formGeneric.errors.required)),
  [inputNames.jobTitle]: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  )
});

const RequestInclusionForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));
  const dispatch = useDispatch();
  const [isSent, setSent] = useState(false);
  const [isError, setError] = useState(false);
  const [checkingSubmiting, setCheckingSubmiting] = useState(false);
  // Text
  const { form } = language.pages.requestInclusion;

  const sendMail = (values, { setSubmitting }) => {
    let { website } = values;
    if (!/^https?:\/\//i.test(values.website)) {
      website = `http://${values.website}`;
    }
    setError(false);
    dispatch(
      sendInclusionRequestEmail({
        mailForm: {
          ...values,
          website
        },
        onFinish: success => {
          setSent(success);
          setError(!success);
          setSubmitting(false);
        }
      })
    );
  };

  return (
    <Formik
      initialValues={{
        logoImage: "",
        organizationName: "",
        shortDescription: "",
        longDescription: "",
        website: "",
        phoneNumber: "",
        emailAddress: "",
        regionsSupported: "",
        givenName: "",
        familyName: "",
        jobTitle: ""
      }}
      validationSchema={formSchema}
      onSubmit={sendMail}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setValues,
        isSubmitting,
        setTouched
      }) => {
        useOnErrorScroll(
          errors,
          inputNamesListOrdered,
          checkingSubmiting,
          newTouched => {
            setTouched({ ...touched, ...newTouched });
            setCheckingSubmiting(false);
          }
        );
        return (
          <Form {...rest} className={`${classes.root} ${className}`}>
            <FormInput
              className={classes.field}
              type="file"
              accept="image/*, .pdf, .eps, .cdr, .ai"
              name="logoImage"
              variant="outlined"
              label={getTextSingle(form.logo)}
              placeholder={getTextSingle(form.logoPlaceholder)}
              error={errors.logoImage}
              touched
              value={values.logoImage && values.logoImage.fileName}
              onChange={event => {
                const file = event.target.files[0];
                if (file) {
                  fromFileToBase64(file, newValue => {
                    setValues({
                      ...values,
                      logoImage: newValue
                    });
                  });
                }
              }}
            />
            <FormInput
              className={classes.field}
              name="organizationName"
              variant="outlined"
              label={getTextSingle(form.organization)}
              error={errors.organizationName}
              touched={touched.organizationName}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="shortDescription"
              variant="outlined"
              label={getTextSingle(form.descriptionShort)}
              placeholder={getTextSingle(form.descriptionShortPlaceholder)}
              error={errors.shortDescription}
              touched={touched.shortDescription}
              onChange={handleChange}
              multiline
            />
            <FormInput
              className={classes.field}
              name="longDescription"
              variant="outlined"
              label={getTextSingle(form.descriptionLong)}
              placeholder={getTextSingle(form.descriptionLongPlaceholder)}
              error={errors.longDescription}
              touched={touched.longDescription}
              onChange={handleChange}
              multiline
            />
            <FormInput
              className={classes.field}
              name="website"
              type="website"
              variant="outlined"
              label={getTextSingle(form.website)}
              error={errors.website}
              touched={touched.website}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="phoneNumber"
              type="tel"
              variant="outlined"
              label={getTextSingle(form.phoneNumber)}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="emailAddress"
              type="email"
              variant="outlined"
              label={getTextSingle(form.emailAdress)}
              error={errors.emailAddress}
              touched={touched.emailAddress}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="publicPostalAddress"
              variant="outlined"
              multiline
              label={getTextSingle(form.publicAddress)}
              error={errors.publicPostalAddress}
              touched={touched.publicPostalAddress}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="regionsSupported"
              variant="outlined"
              label={getTextSingle(form.region)}
              placeholder={
                isMobile
                  ? getTextSingle(form.regionPlaceholderMobile)
                  : getTextSingle(form.regionPlaceholder)
              }
              error={errors.regionsSupported}
              touched={touched.regionsSupported}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              type="file"
              accept="application/pdf"
              name="document"
              variant="outlined"
              label={getTextSingle(form.information)}
              placeholder={getTextSingle(form.informationPlaceholder)}
              value={values.document && values.document.fileName}
              error={errors.file}
              touched={touched.file}
              onChange={event => {
                const file = event.target.files[0];
                if (file)
                  fromFileToBase64(file, newValue => {
                    setValues({
                      ...values,
                      document: newValue
                    });
                  });
              }}
            />
            <FormInput
              className={classes.field}
              name="givenName"
              variant="outlined"
              label={getTextSingle(form.givenName)}
              error={errors.givenName}
              touched={touched.givenName}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="familyName"
              variant="outlined"
              label={getTextSingle(form.lastName)}
              error={errors.familyName}
              touched={touched.familyName}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="jobTitle"
              variant="outlined"
              label={getTextSingle(form.postion)}
              error={errors.jobTitle}
              touched={touched.jobTitle}
              onChange={handleChange}
            />
            <Button
              disabled={isSubmitting}
              className={classes.submit}
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                setCheckingSubmiting(true);
              }}
            >
              {isSent ? (
                <>
                  {getTextSingle(form.sent)}
                  <CheckIcon />
                </>
              ) : (
                getTextSingle(form.send)
              )}
            </Button>
            {isError && (
              <Text type={types.fieldError}>
                {getText(language.formGeneric.errors.error)}
              </Text>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default RequestInclusionForm;
