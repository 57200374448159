import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Assets
import logo from "../../assets/logos/main-logo.svg";

// Components
import AccreditationSection from "./AccreditationSection";
import HeroSection from "./HeroSection";
import HowItWorksMini from "./HowItWorksMini";
import Section from "../../components/Section/Section";
import StartJourney from "../../components/StartJourney/StartJourney";
import EvidenceCollectionSection from "../../components/EvidenceCollection/EvidenceCollectionSection";
import StoringEvidenceSection from "../../components/StoringEvidence/StoringEvidenceSection";

const useStyles = makeStyles(theme => ({
  logo: {
    height: 100,
    width: 100,
    marginBottom: 20,
    backgroundImage: `url(${logo})`,
    backgroundSize: "cover",
    [theme.breakpoints.down("xs")]: {
      height: 70,
      width: 70
    }
  },
  sectionCross: {
    marginTop: "-8%",
    paddingTop: 0,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0
    }
  }
}));

const Home = () => {
  const classes = useStyles();

  return (
    <>
      <HeroSection />
      <EvidenceCollectionSection />
      <StoringEvidenceSection />
      <Section className={classes.sectionCross}>
        <AccreditationSection />
      </Section>
      <StartJourney />
      <HowItWorksMini />
    </>
  );
};

export default Home;
