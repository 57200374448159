import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSupportLines, setCoord } from "../state/SupportLines/actions";


const useEmergencyContacts = ({ askForLocation = false } = {}) => {
	const dispatch = useDispatch();
	// Reducer Data
	const {
		supportLines,
		loading,
		loadingEmergency,
		error,
		errorEmergency,
		coordinates,
		...rest
	} = useSelector((state) => state.supportLines);

	useEffect(() => {
		if (!coordinates && !supportLines && askForLocation){
			navigator.geolocation.getCurrentPosition((userLocation)=>{
				dispatch(setCoord(userLocation.coords));
			},
			() => {
				dispatch(setCoord({}));
			});
		}
		else {
			const coord =  coordinates !== null ? coordinates : {};
			if (!error && !loading && !supportLines){
				dispatch(getSupportLines({ coordinates: coord}))
			}
		}
	}, [dispatch, coordinates, supportLines, loading, loadingEmergency, error, errorEmergency, askForLocation])

	return { supportLines, loading, loadingEmergency, coordinates, dispatch, ...rest }
}

export default useEmergencyContacts;