import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Utils
import { getRoutes } from "../config/routes";
import { HandlePageEnter } from "../config/googleAnalitycs";
import smartBanner from "../utils/smartBanner";
import initHelpScount from "../helpscout";

// Components
import CookiesBanner from "../components/CookiesBanner/CookiesBanner";
import DocumentTitle from "../components/DocumentTitle";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";

const RouteRender = ({ route, ...props }) => {
  const {
    isBannerOpen,
    cookies: { analytical }
  } = useSelector(state => state.cookies);
  useEffect(() => {
    // Banner must have been closed and analytical accepted
    if (!isBannerOpen && analytical) {
      HandlePageEnter(route.path);
    }
  }, [route.path, isBannerOpen, analytical]);

  useEffect(() => {
    // Initializes smart banner only after cookie banner is closed
    if (!isBannerOpen) {
      smartBanner();
      initHelpScount();
    }
  }, [isBannerOpen]);

  return (
    <DocumentTitle title={route.title} ldJson={route.ldJson}>
      <route.component
        {...props}
        {...route.props}
        {...route.actions}
        routes={route.routes}
      />
    </DocumentTitle>
  );
};

const RouteHandler = ({ ...route }) => (
  <Route
    exact={route.isExact}
    path={route.path}
    render={props => <RouteRender {...props} route={route} />}
  />
);

const Routes = () => {
  const routes = getRoutes();

  return (
    <Router>
      <Header />
      <Switch>
        {routes.map(route => (
          <RouteHandler key={route.id} {...route} />
        ))}
      </Switch>
      <CookiesBanner />
      <Footer />
    </Router>
  );
};

export default Routes;
