import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

// Backgrounds
import cloud1 from "../../assets/backgrounds/cloud1.png";
import cloud2 from "../../assets/backgrounds/cloud2.png";

import styles from "../../config/styles";

const useStyles = makeStyles(theme => ({
  sectionRoot: {
    ...styles.mixins.mainSection,
    paddingTop: styles.mixins.mainSection.paddingTop,
    paddingBottom: "5%",
    paddingLeft: "6.5%",
    paddingRight: "6.5%",
    position: "relative",
    color: theme.palette.common.black,
    transition: ".5s padding ease-in-out",
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "25%",
      paddingRight: "25%"
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: styles.mixins.mainSectionMid.paddingTop,
      paddingLeft: "5%",
      paddingRight: "5%"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8%",
      paddingRight: "8%"
    },
    [theme.breakpoints.down("xs")]: {
      background: theme.palette.background.default,
      paddingTop: ({ isMain }) =>
        16 + (isMain ? styles.mixins.mainSection.paddingTop * 0.6 : 0),
      paddingBottom: theme.spacing(2)
    }
  },
  cloud: {
    position: "absolute",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    zIndex: -5
  },
  cloud1: {
    backgroundImage: `url(${cloud1})`,
    right: 0,
    top: 200,
    width: "19.3vw",
    height: "22vw",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  cloud2: {
    top: 700,
    left: 0,
    backgroundImage: `url(${cloud2})`,
    width: "23vw",
    height: "30vw",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  logo: {
    position: "absolute",
    height: 40,
    opacity: 0.8
  }
}));

const Section = ({ className, isMain, children, ...props }) => {
  const classes = useStyles({ isMain });

  return (
    <>
      <section className={`${classes.sectionRoot} ${className}`} {...props}>
        {isMain && (
          <>
            <div className={clsx(classes.cloud, classes.cloud1)} />
            <div className={clsx(classes.cloud, classes.cloud2)} />
          </>
        )}
        {children}
      </section>
    </>
  );
};

export default Section;
