import ReactGA from "react-ga4";

const GAID = "G-X1CXTZ38Y3";

const canSend =
  process.env.REACT_APP_ENABLE_GA ||
  process.env.REACT_APP_ENVIRONMENT === "production";

export const HandlePageEnter = pageId => {
  if (canSend) {
    // ReactGA.send({ hitType: "pageview", page: pageId });
  }
};

export const analyticsClassNames = {
  downloadLink: "downloadLink", // login and get app buttons
  iosStoreLink: "iosStoreLink",
  androidStoreLink: "androidStoreLink"
};

const Initialize = () => {
  if (canSend) {
    ReactGA.initialize(GAID, {
      gtagOptions: {
        consent: {
          ad_storage: "denied",
          analytics_storage: "denied"
        }
      }
    });
  }
};

export default Initialize;
