import React from "react";
import { HashLink } from "react-router-hash-link";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    color: "inherit",
  },
}));

const LinkScroll = ({ children, className, ...rest }) => {
  const classes = useStyles();

  const scrollWithOffset = (el, offset) => {
    setTimeout(() => {
      const elementPosition = el.offsetTop - offset;
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth",
      });
    }, 350);
  };

  return (
    <HashLink
      className={`${classes.root} ${className}`}
      {...rest}
      smooth
      scroll={(el) => scrollWithOffset(el, 100)}
    >
      {children}
    </HashLink>
  );
};

export default LinkScroll;
