/* eslint-disable react/no-array-index-key */
import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Contents
import Text, { types } from "../Text/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%"
  },
  content: {
    marginBottom: theme.spacing(4),
    maxWidth: "80vw",
    width: "100%"
  },
  image: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    width: "100%",
    maxWidth: 328,
    margin: "0 auto",
    display: "block"
  },
  heading: {
    marginBottom: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  description: {
    marginBottom: theme.spacing(1)
  }
}));

const HomeSubsectionResponsive = ({ subsections }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {subsections.map(
        ({ heading, title, descriptions, mobileBg, id }, index) => (
          <div key={id} className={classes.content}>
            {mobileBg && (
              <img src={mobileBg} alt="" className={classes.image} />
            )}
            <Text
              type={types.subsectionHeadingMobile}
              className={classes.heading}
            >
              {index + 1}. {heading}
            </Text>
            <Text type={types.subsectionTitleMobile} className={classes.title}>
              {title}
            </Text>
            {descriptions.map((data, i) => (
              <Text
                key={i}
                type={types.subsectionDescriptionMobile}
                className={classes.description}
              >
                {data}
              </Text>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default HomeSubsectionResponsive;
