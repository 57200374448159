import React from "react";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";

// Config
import { externalRoutes } from "../../config/urls";
import colors from "../../config/colors";

// Assets
import heroImage from "../../assets/images/hero-image.png";
import appStoreLogo from "../../assets/logos/app-store.png";
import playStoreLogo from "../../assets/logos/google-play.png";

// Components
import Section from "../../components/Section/Section";
import HighlightColor from "../../components/HighlightColor/index";
import { types } from "../../components/Text/Text";
import { analyticsClassNames } from "../../config/googleAnalitycs";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative"
  },
  contentWrapper: {
    padding: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(15),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: 0
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(4)
    }
  },
  topTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: theme.spacing(3),
    color: colors.riverBed,
    fontSize: 28,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      width: "80%",
      marginBottom: theme.spacing(3)
    }
  },
  brandSpan: {
    color: colors.lightBlue,
    fontWeight: "bold"
  },
  mainTitle: {
    fontFamily: ["Montserrat", "Open Sans", "sans-serif"].join(","),
    fontWeight: 800,
    color: colors.black,
    fontSize: 99,
    marginBottom: theme.spacing(4),
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 56
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 42
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 28
    }
  },
  appButton: {
    fontFamily: ["Montserrat", "Open Sans", "sans-serif"].join(","),
    display: "flex",
    margin: "0 auto",
    textTransform: "initial",
    fontSize: 20,
    width: 430,
    height: 72,
    padding: 0,
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 3px 56px #00000029",
    [theme.breakpoints.down("md")]: {
      width: 240,
      height: 48,
      fontSize: 17
    }
  },
  bold: {
    fontWeight: "bold",
    margin: "0 0.5ch"
  },
  mainImage: {
    display: "flex",
    width: "40vw",
    maxWidth: 755,
    margin: theme.spacing(6, 0),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3.5, 0),
      width: "30vw"
    },
    [theme.breakpoints.down("sm")]: {
      width: "70vw",
      maxWidth: 540
    }
  },
  appStoresContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  storeBadge: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    margin: theme.spacing(0, 2.25),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(0, 1),
      width: 150
    },
    [theme.breakpoints.down(940)]: {
      width: 108
    }
  },
  storeImage: {
    width: "100%"
  },
  changeReminder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    fontSize: 28,
    whiteSpace: "break-spaces",
    [theme.breakpoints.down("md")]: {
      fontSize: 17
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 22
    }
  },
  kulpaHightlight: {
    marginLeft: theme.spacing(0.5),
    fontWeight: "bold"
  },
  learnWhy: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    whiteSpace: "break-spaces",
    fontSize: 28,
    marginBottom: theme.spacing(6),
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 18,
      marginBottom: theme.spacing(3)
    }
  }
}));

const HeroSection = () => {
  const classes = useStyles();

  return (
    <Section className={classes.root} isMain>
      <div className={classes.contentWrapper}>
        <Typography className={classes.topTitle}>
          Use <HighlightColor>kulpa</HighlightColor> to securely store legally
          admissible evidence
        </Typography>
        <Typography
          className={classes.mainTitle}
          type={types.miniSectionTitle}
          component="h1"
        >
          Evidence is Everything
        </Typography>
        <Button
          className={clsx(classes.appButton, analyticsClassNames.downloadLink)}
          color="secondary"
          component="a"
          disableElevation
          href={externalRoutes.webapp}
          rel="noreferrer"
          target="_blank"
          variant="contained"
        >
          Get the free <span className={classes.bold}>kulpa</span> app
        </Button>
        <img src={heroImage} className={classes.mainImage} alt="" />
        <div className={classes.appStoresContainer}>
          <ButtonBase
            aria-label="App Store"
            className={clsx(
              classes.storeBadge,
              analyticsClassNames.iosStoreLink
            )}
            component="a"
            href={externalRoutes.appStore}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={appStoreLogo} alt="" className={classes.storeImage} />
          </ButtonBase>
          <ButtonBase
            aria-label="Google Play"
            className={clsx(
              classes.storeBadge,
              analyticsClassNames.androidStoreLink
            )}
            component="a"
            href={externalRoutes.googlePlay}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img src={playStoreLogo} alt="" className={classes.storeImage} />
          </ButtonBase>
        </div>
      </div>
    </Section>
  );
};

export default HeroSection;
