import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem, Button } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Icons
import CheckIcon from "@material-ui/icons/Check";

// Languages
import { language, getTextSingle } from "../../languages/Languages";
import FormInput from "./FormInput";
import colors from "../../config/colors";
import { sendContactEmail } from "../../state/Emails/actions";
import Text, { types } from "../Text/Text";
import useOnErrorScroll from "../../hooks/useOnErrorScroll";

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    position: "relative"
  },
  bigIcon: {
    marginTop: theme.spacing(6)
  },
  formSection: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
  },
  field: {
    marginBottom: `${theme.spacing(1)}px`
  },
  submit: {
    color: colors.white,
    minWidth: 160,
    width: "6vw",
    padding: theme.spacing(1, 5),
    borderRadius: 40,
    border: "none",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.6vw"
    },
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "space-evenly"
    },
    "&.Mui-disabled": {
      color: colors.black,
      background: colors.grey
    }
  }
}));

const SignupSchema = Yup.object().shape({
  givenName: Yup.string()
    .max(50, getTextSingle(language.formGeneric.errors.tooLong))
    .required(getTextSingle(language.formGeneric.errors.required)),
  familyName: Yup.string()
    .max(50, getTextSingle(language.formGeneric.errors.tooLong))
    .required(getTextSingle(language.formGeneric.errors.required)),
  email: Yup.string()
    .email(getTextSingle(language.formGeneric.errors.invalidEmail))
    .required(getTextSingle(language.formGeneric.errors.required)),
  userType: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  ),
  phoneNumber: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  ),
  message: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  )
});

const { medicalProfessional, policeOfficer, other } = language.common.userTypes;

const userTypes = [
  {
    value: "Relative",
    label: `${getTextSingle(other)}`
  },
  {
    value: "MedicalProfessional",
    label: getTextSingle(medicalProfessional)
  },
  {
    value: "PoliceOfficer",
    label: getTextSingle(policeOfficer)
  }
];

const inputNames = {
  givenName: "givenName",
  familyName: "familyName",
  email: "email",
  phoneNumber: "phoneNumber",
  message: "message"
};

const inputNamesOrdered = [
  inputNames.givenName,
  inputNames.familyName,
  inputNames.email,
  inputNames.phoneNumber,
  inputNames.message
];

const ContactForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSent, setSent] = useState(false);
  const [isError, setError] = useState(false);
  const [checkingSubmiting, setCheckingSubmiting] = useState(false);
  // Text
  const { contactEmail } = language.pages.contactSupport;
  const {
    userType,
    firstName,
    lastName,
    phoneNumber,
    emailAdress,
    message,
    send,
    sent
  } = contactEmail;

  const sendMail = (values, { setSubmitting }) => {
    dispatch(
      sendContactEmail({
        mailForm: values,
        onFinish: success => {
          setSent(success);
          setError(!success);
          setSubmitting(false);
        }
      })
    );
  };

  return (
    <Formik
      initialValues={{
        givenName: "",
        familyName: "",
        email: "",
        phoneNumber: "",
        message: "",
        userType: "Relative"
      }}
      validationSchema={SignupSchema}
      onSubmit={sendMail}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        isSubmitting,
        setTouched
      }) => {
        useOnErrorScroll(
          errors,
          inputNamesOrdered,
          checkingSubmiting,
          newTouched => {
            setTouched({ ...touched, ...newTouched });
            setCheckingSubmiting(false);
          }
        );
        return (
          <Form {...rest} className={`${classes.root} ${className}`}>
            <FormInput
              className={classes.field}
              name="userType"
              select
              variant="outlined"
              label={getTextSingle(userType)}
              value={values.userType}
              error={errors.userType}
              touched={touched.userType}
              onChange={handleChange}
            >
              {userTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </FormInput>
            <FormInput
              className={classes.field}
              name="givenName"
              variant="outlined"
              label={getTextSingle(firstName)}
              error={errors.givenName}
              touched={touched.givenName}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="familyName"
              variant="outlined"
              label={getTextSingle(lastName)}
              error={errors.familyName}
              touched={touched.familyName}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="email"
              type="email"
              variant="outlined"
              label={getTextSingle(emailAdress)}
              error={errors.email}
              touched={touched.email}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="phoneNumber"
              type="tel"
              variant="outlined"
              label={getTextSingle(phoneNumber)}
              error={errors.phoneNumber}
              touched={touched.phoneNumber}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="message"
              variant="outlined"
              label={getTextSingle(message)}
              error={errors.message}
              touched={touched.message}
              onChange={handleChange}
              multiline
            />
            <Button
              disabled={isSubmitting}
              className={classes.submit}
              type="submit"
              variant="contained"
              color="secondary"
              onClick={() => {
                setCheckingSubmiting(true);
              }}
            >
              {isSent ? (
                <>
                  {getTextSingle(sent)}
                  <CheckIcon />
                </>
              ) : (
                getTextSingle(send)
              )}
            </Button>
            {isError && (
              <Text type={types.fieldError}>
                {getTextSingle(language.formGeneric.errors.error)}
              </Text>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
