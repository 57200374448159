import { combineReducers } from "redux";
import constant from "lodash-es/constant";
import createReducer from "../../utils/createReducer";

import { types } from "./actions";

const {
  TERMS_AND_CONDITIONS_REQUEST,
  TERMS_AND_CONDITIONS_SUCCESS,
  TERMS_AND_CONDITIONS_FAIL,
  PRIVACY_REQUEST,
  PRIVACY_SUCCESS,
  PRIVACY_FAIL,
  COOKIES_REQUEST,
  COOKIES_SUCCESS,
  COOKIES_FAIL
} = types;

const isLoadingTerms = createReducer(false, {
  [TERMS_AND_CONDITIONS_REQUEST]: constant(true),
  [TERMS_AND_CONDITIONS_SUCCESS]: constant(false),
  [TERMS_AND_CONDITIONS_FAIL]: constant(false)
});

const isLoadingPrivacy = createReducer(false, {
  [PRIVACY_REQUEST]: constant(true),
  [PRIVACY_SUCCESS]: constant(false),
  [PRIVACY_FAIL]: constant(false)
});

const isLoadingCookies = createReducer(false, {
  [COOKIES_REQUEST]: constant(true),
  [COOKIES_SUCCESS]: constant(false),
  [COOKIES_FAIL]: constant(false)
});

const termsData = createReducer(null, {
  [TERMS_AND_CONDITIONS_SUCCESS]: (state, { payload }) => payload.text
});

const privacyData = createReducer(null, {
  [PRIVACY_SUCCESS]: (state, { payload }) => payload.text
});

const cookiesData = createReducer(null, {
  [COOKIES_SUCCESS]: (state, { payload }) => payload.text
});

const error = createReducer(null, {
  [TERMS_AND_CONDITIONS_FAIL]: (state, { payload }) => payload,
  [TERMS_AND_CONDITIONS_REQUEST]: constant(null)
});

const errorPrivacy = createReducer(null, {
  [PRIVACY_FAIL]: (state, { payload }) => payload,
  [PRIVACY_REQUEST]: constant(null)
});

const errorCookies = createReducer(null, {
  [COOKIES_FAIL]: (state, { payload }) => payload,
  [COOKIES_REQUEST]: constant(null)
});

export default combineReducers({
  termsData,
  privacyData,
  cookiesData,
  error,
  errorPrivacy,
  errorCookies,
  isLoadingTerms,
  isLoadingPrivacy,
  isLoadingCookies
});
