/* eslint-disable react/no-array-index-key */
import React from "react";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Assets
import headingArrow from "../../assets/icons/heading-arrow.svg";

// Config
import colors from "../../config/colors";

// Components
import Text, { types } from "../Text/Text";

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(1),
    maxWidth: 716
  },
  headingArrow: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 8,
      marginRight: theme.spacing(1)
    }
  },
  title: {
    whiteSpace: "pre-line",
    maxWidth: 650,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      maxWidth: 494,
      marginBottom: theme.spacing(2)
    }
  },
  bigTitle: {
    [theme.breakpoints.up("lg")]: {
      fontSize: 56,
      maxWidth: 635
    }
  },
  description: {
    marginBottom: theme.spacing(3),
    maxWidth: 700,
    lineHeight: 1.4,
    "& span": {
      color: colors.lightBlue,
      fontWeight: "bold"
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 494,
      marginBottom: theme.spacing(2)
    }
  }
}));

const HomeSubsection = ({
  heading,
  title,
  descriptions = [],
  children,
  bigTitle
}) => {
  const classes = useStyles();
  return (
    <div>
      <Text
        type={types.subsectionHeading}
        className={classes.heading}
        component="h3"
      >
        <img src={headingArrow} alt="" className={classes.headingArrow} />
        {heading}
      </Text>
      <Text
        type={types.subsectionTitle}
        className={clsx(classes.title, { [classes.bigTitle]: bigTitle })}
      >
        {title}
      </Text>
      {descriptions.map((content, i) => (
        <Text
          key={i}
          type={types.subsectionDescription}
          className={classes.description}
        >
          {content}
        </Text>
      ))}
      {children}
    </div>
  );
};

export default HomeSubsection;
