import { useEffect } from "react";
import scrollToTargetAdjusted from "../utils/documentUtils";

const useOnErrorScroll = (
  errors,
  inputNameListOrdered,
  validating,
  onValidate
) => {
  useEffect(() => {
    const errorList = Object.keys(errors);
    const topError = inputNameListOrdered.filter(i => errorList.includes(i))[0];
    if (validating && topError) {
      scrollToTargetAdjusted(topError, 200);
      onValidate({ [topError]: true });
    }
  }, [validating, errors]);
};

export default useOnErrorScroll;
