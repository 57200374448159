/* eslint-disable */
function init() {
  (function (w, d, s, l, i) {
    console.log("gtm start");
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js"
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src =
      "https://www.googletagmanager.com/gtm.js?id=" +
      i +
      dl +
      "&gtm_auth=en3mVcysHOTVJpFHWKKJHA&gtm_preview=env-8&gtm_cookies_win=x";
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", "GTM-TWNV6W8");
}

export default init;
