import { getSupportLinesUrl } from "../../config/Api";

export const types = {
    SUPPORT_LINES_REQUEST: "SUPPORT_LINES_REQUEST",
    SUPPORT_LINES_SUCCESS: "SUPPORT_LINES_SUCCESS",
    SUPPORT_LINES_FAIL: "SUPPORT_LINES_FAIL",
    EMERGENCY_LINES_REQUEST: "EMERGENCY_LINES_REQUEST",
    EMERGENCY_LINES_SUCCESS: "EMERGENCY_LINES_SUCCESS",
    EMERGENCY_LINES_FAIL: "EMERGENCY_LINES_FAIL",
    EMERGENCY_BANNER_CLOSE: "EMERGENCY_BANNER_CLOSE",
    SET_COORDINATES: "SET_COORDINATES"
};

const requestSupportLines = () => ({
    type: types.SUPPORT_LINES_REQUEST,
  });
const requestSupportLinesSuccess = (payload) => ({
    type: types.SUPPORT_LINES_SUCCESS,
    payload,
});
const requestSupportLinesFailure = (payload) => ({
    type: types.SUPPORT_LINES_FAIL,
    payload,
});
export const emergencyBannerClose = () => ({
  type: types.EMERGENCY_BANNER_CLOSE,
});
export const setCoord = (payload) => ({
    type: types.SET_COORDINATES,
    payload,
})

export const getSupportLines = ({
    coordinates: {
        latitude,
        longitude
    },
  }) => (dispatch) => {
    dispatch(requestSupportLines())
    fetch(getSupportLinesUrl(latitude, longitude), {
      method: "GET",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(requestSupportLinesSuccess(data))
      })
      .catch(({ message }) => {
        dispatch(requestSupportLinesFailure(message))
      });
  };