/* eslint-disable react/no-danger */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Components
import Text, { types } from "../Text/Text";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

const useStyles = makeStyles(theme => ({
  intro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    position: "relative",
    width: "100%",
    textAlign: "left",
    marginBottom: theme.spacing(5)
  },
  contractText: {
    width: "100%",
    fontSize: 17,
    marginTop: theme.spacing(2)
  },
  contractTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(0),
      fontWeight: 500
    }
  }
}));

const getFormatedText = text => {
  if (!text) return "";
  const content = <span dangerouslySetInnerHTML={{ __html: text }} />;
  return content;
};

const TermsAndConditions = ({ text, title, isLoading, children, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.intro} {...rest}>
      <Text className={classes.contractTitle} type={types.supportLineTitle}>
        {title}
      </Text>
      {isLoading ? (
        <LoadingCircle />
      ) : (
        <Text className={classes.contractText} type={types.answer}>
          {getFormatedText(text)}
        </Text>
      )}
      {children}
    </div>
  );
};

export default TermsAndConditions;
