import { getPressReleaseUrl } from "../../config/Api";

export const types = {
  PRESS_RELEASE_LIST_REQUEST: "PRESS_RELEASE_LIST_REQUEST",
  PRESS_RELEASE_LIST_SUCCESS: "PRESS_RELEASE_LIST_SUCCESS",
  PRESS_RELEASE_LIST_FAIL: "PRESS_RELEASE_LIST_FAIL",
  PRESS_RELEASE_REQUEST: "PRESS_RELEASE_REQUEST",
  PRESS_RELEASE_SUCCESS: "PRESS_RELEASE_SUCCESS",
  PRESS_RELEASE_FAIL: "PRESS_RELEASE_FAIL",
};

// Terms and conditions
const pressReleaseListRequest = () => ({
  type: types.PRESS_RELEASE_LIST_REQUEST,
});
const pressReleaseListSuccess = (payload) => ({
  type: types.PRESS_RELEASE_LIST_SUCCESS,
  payload,
});
const pressReleaseListFailure = (payload) => ({
  type: types.PRESS_RELEASE_LIST_FAIL,
  payload,
});

// Terms and conditions
const pressReleaseRequest = () => ({
  type: types.PRESS_RELEASE_REQUEST,
});
const pressReleaseSuccess = (payload) => ({
  type: types.PRESS_RELEASE_SUCCESS,
  payload,
});
const pressReleaseFailure = (payload) => ({
  type: types.PRESS_RELEASE_FAIL,
  payload,
});

export const getPressReleaseId = ({ pressReleaseId }) => (dispatch) => {
  dispatch(pressReleaseRequest())
  fetch(`${getPressReleaseUrl()}/${pressReleaseId}`, {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(pressReleaseSuccess(data));
    })
    .catch(({ message }) => {
      dispatch(pressReleaseFailure(message));
    });
};

export const getPressReleases = () => (dispatch) => {
  dispatch(pressReleaseListRequest())
  fetch(getPressReleaseUrl(), {
    method: "GET",
    headers: {
      accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      dispatch(pressReleaseListSuccess(data));
    })
    .catch(({ message }) => {
      dispatch(pressReleaseListFailure(message));
    });
};
