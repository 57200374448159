import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/icons/Publish";
import UploadIcon from "@material-ui/icons/ArrowUpwardOutlined";

// Languages
import Text, { types } from "../Text/Text";
import colors from "../../config/colors";

const useStyles = makeStyles(theme => ({
  field: {
    margin: `${theme.spacing(1)}px 0`,
    width: "100%",
    "&> .MuiTextField-root": {
      width: "100%"
    },
    "& .MuiInputBase-root": {
      minHeight: ({ multiline }) => multiline && 80,
      display: ({ multiline }) => multiline && "flex",
      alignItems: ({ multiline }) => multiline && "flex-start",
      boxShadow: "0px 0px 20px 4px rgb(0 0 0 / 5%)",
      border: ({ error }) => (error ? `1px solid ${colors.error}` : "none"),
      [theme.breakpoints.up("xl")]: {
        fontSize: "0.5vw"
      }
    }
  },
  fieldNoError: {
    marginBottom: theme.spacing(2.4)
  },
  input: {
    "&> .MuiInputBase-root": {
      borderRadius: 12,
      [theme.breakpoints.down("xs")]: {
        backgroundColor: "white"
      }
    }
  },
  hidden: {
    display: "none"
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    merginBottom: theme.spacing(1)
  }
}));

const FormInput = ({
  className,
  classNameInput,
  error,
  touched: propsTouched,
  label,
  description,
  type,
  value,
  multiline,
  name,
  ...rest
}) => {
  const [touched, setTouched] = useState(propsTouched);
  const showError = touched && error;
  const classes = useStyles({ multiline, error: showError });
  const ref = useRef();

  useEffect(() => {
    if (propsTouched) {
      setTouched(true);
    }
  }, [propsTouched]);

  let InputComponent = (
    <TextField
      variant="outlined"
      multiline={multiline}
      type={type}
      value={value}
      name={name}
      {...rest}
      className={`${classes.input} ${classNameInput}`}
    />
  );
  if (type === "file") {
    InputComponent = (
      <>
        <TextField
          variant="outlined"
          type="text"
          hidden
          value={value}
          disabled
          name={name}
          {...rest}
          className={`${classes.input} ${classNameInput}`}
          onClick={() => ref.current.click()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <UploadIcon />
              </InputAdornment>
            )
          }}
        />
        <input
          {...rest}
          ref={ref}
          className={classes.hidden}
          id="contained-button-file"
          type="file"
        />
      </>
    );
  }
  return (
    <div className={`${classes.field} ${className}`} id={name}>
      <div className={classes.inputContainer}>
        <Text type={types.inputLabel}>
          {label}
          {showError && " ·"}
        </Text>
        {showError && <Text type={types.fieldError}>{error}</Text>}
      </div>
      {description && <Text type={types.inputDescription}>{description}</Text>}
      {InputComponent}
    </div>
  );
};

export default FormInput;
