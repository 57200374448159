// Containers
import ContactSupport from "../containers/ContactSupport";
import CookiePolicy from "../containers/CookiePolicy";
import Faqs from "../containers/Faqs";
import HomePage from "../containers/HomePage/HomePage";
import PressRelease from "../containers/PressRelease/index";
import PressReleaseSingle from "../containers/PressReleaseSingle/index";
import TermsAndConditions from "../containers/TermsAndConditions";
import VictimSupport from "../containers/VictimSupport";
import PromotionalMaterial from "../containers/PromotionalMaterial";

import { urls } from "./urls";
import RequestInclusion from "../containers/RequestInclusion";

// Routes
export const routes = [
  {
    component: VictimSupport,
    title: "Victim Support",
    id: "victimSupport",
    path: urls.victimSupport
  },
  {
    component: ContactSupport,
    title: "Contact / Support",
    id: "contactSupport",
    path: urls.contactSupport
  },
  {
    component: PromotionalMaterial,
    title: "Promotional Material",
    id: "promotionalMaterial",
    path: urls.promotionalMaterial
  },
  {
    component: RequestInclusion,
    title: "Request Inclusion",
    id: "requestInclusion",
    path: urls.requestInclusion
  },
  {
    component: Faqs,
    title: "FAQs",
    id: "faqs",
    path: urls.faqs
  },
  {
    component: TermsAndConditions,
    title: "Terms And Conditions",
    id: "terms",
    path: urls.termsAndConditions,
    subs: {
      termsAndConditions: "#termsAndConditions",
      privacyPolicy: "#privacyPolicy"
    },
    isExact: false
  },
  {
    component: CookiePolicy,
    title: "Cookie Policy",
    id: "cookie",
    path: urls.cookie,
    isExact: false
  },
  {
    component: PressReleaseSingle,
    title: "Press Releases",
    id: "pressReleaseWithId",
    path: urls.pressReleasesWithId,
    isExact: true
  },
  {
    component: PressRelease,
    title: "Press Releases",
    id: "pressRelease",
    path: urls.pressReleases,
    isExact: true
  },
  {
    component: HomePage,
    title: "kulpa",
    id: "home",
    path: urls.home
  }
];

export const getRoutes = () => routes;
