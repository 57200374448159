import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, InputAdornment } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";

// Icons
import CheckIcon from "@material-ui/icons/Check";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";

// Languages
import { language, getTextSingle } from "../../languages/Languages";
import FormInput from "../ContactForm/FormInput";
import colors from "../../config/colors";
import { sendPromotionalEmail } from "../../state/PromotionalMaterial/actions";
import { getPhoneNumberFormat } from "../../utils/formatUtils";
import Text, { types } from "../Text/Text";
import useOnErrorScroll from "../../hooks/useOnErrorScroll";

const useStyles = makeStyles(theme => ({
  root: {
    display: "block",
    position: "relative"
  },
  bigIcon: {
    marginTop: theme.spacing(6)
  },
  formSection: {
    padding: theme.spacing(4)
  },
  field: {
    marginBottom: `${theme.spacing(1)}px`
  },
  submit: {
    color: colors.white,
    minWidth: 160,
    width: "6vw",
    padding: theme.spacing(1, 5),
    borderRadius: 40,
    border: "none",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.6vw"
    },
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "space-evenly"
    },
    "&.Mui-disabled": {
      color: colors.black,
      background: colors.grey
    }
  }
}));

const inputNames = {
  OrganizationName: "OrganizationName",
  PhoneNumber: "PhoneNumber",
  EmailAddress: "EmailAddress",
  Website: "Website",
  RequestDetail: "RequestDetail",
  PostalAddress: "PostalAddress"
};

const inputNamesOrdered = [
  inputNames.OrganizationName,
  inputNames.PhoneNumber,
  inputNames.EmailAddress,
  inputNames.Website,
  inputNames.RequestDetail,
  inputNames.PhoneNumber
];

const formSchema = Yup.object().shape({
  OrganizationName: Yup.string()
    .max(50, getTextSingle(language.formGeneric.errors.tooLong))
    .required(getTextSingle(language.formGeneric.errors.required)),
  PhoneNumber: Yup.string()
    .max(50, getTextSingle(language.formGeneric.errors.tooLong))
    .matches(
      getPhoneNumberFormat(),
      getTextSingle(language.formGeneric.errors.invalidPhone)
    )
    .required(getTextSingle(language.formGeneric.errors.required)),
  EmailAddress: Yup.string()
    .email(getTextSingle(language.formGeneric.errors.invalidEmail))
    .required(getTextSingle(language.formGeneric.errors.required)),
  Website: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  ),
  RequestDetail: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  ),
  PostalAddress: Yup.string().required(
    getTextSingle(language.formGeneric.errors.required)
  )
});

const PromotionalForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSent, setSent] = useState(false);
  const [isError, setError] = useState(false);
  const [checkingSubmiting, setCheckingSubmiting] = useState(false);
  // Text
  const { form } = language.pages.promotionalMaterial;

  const sendMail = (values, { setSubmitting }) => {
    let { Website } = values;
    if (!/^https?:\/\//i.test(values.Website)) {
      Website = `http://${values.Website}`;
    }
    dispatch(
      sendPromotionalEmail({
        mailForm: {
          ...values,
          Website
        },
        onFinish: success => {
          setSent(success);
          setError(!success);
          setSubmitting(false);
        }
      })
    );
  };

  return (
    <Formik
      initialValues={{
        OrganizationName: "",
        PhoneNumber: "",
        Website: "",
        EmailAddress: "",
        PostalAddress: "",
        RequestDetail: ""
      }}
      validationSchema={formSchema}
      onSubmit={sendMail}
    >
      {({ errors, touched, handleChange, isSubmitting, setTouched }) => {
        useOnErrorScroll(
          errors,
          inputNamesOrdered,
          checkingSubmiting,
          newTouched => {
            setTouched({ ...touched, ...newTouched });
            setCheckingSubmiting(false);
          }
        );
        return (
          <Form {...rest} className={`${classes.root} ${className}`}>
            <FormInput
              className={classes.field}
              name="OrganizationName"
              variant="outlined"
              label={getTextSingle(form.organization)}
              error={errors.OrganizationName}
              touched={touched.OrganizationName}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="PhoneNumber"
              type="tel"
              variant="outlined"
              label={getTextSingle(form.phoneNumber)}
              error={errors.PhoneNumber}
              touched={touched.PhoneNumber}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="EmailAddress"
              type="email"
              variant="outlined"
              label={getTextSingle(form.emailAdress)}
              error={errors.EmailAddress}
              touched={touched.EmailAddress}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="Website"
              type="website"
              variant="outlined"
              label={getTextSingle(form.website)}
              error={errors.Website}
              touched={touched.Website}
              onChange={handleChange}
            />
            <FormInput
              className={classes.field}
              name="PostalAddress"
              variant="outlined"
              label={getTextSingle(form.adress)}
              error={errors.PostalAddress}
              touched={touched.PostalAddress}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <GpsFixedIcon />
                  </InputAdornment>
                )
              }}
            />
            <FormInput
              className={classes.field}
              name="RequestDetail"
              variant="outlined"
              label={getTextSingle(form.descriptions)}
              placeholder={getTextSingle(form.descriptionsPlaceholder)}
              description={getTextSingle(form.descriptionsDescription)}
              error={errors.RequestDetail}
              touched={touched.RequestDetail}
              onChange={handleChange}
              multiline
            />
            <Button
              disabled={isSubmitting}
              className={classes.submit}
              type="submit"
              color="secondary"
              variant="contained"
              onClick={() => {
                setCheckingSubmiting(true);
              }}
            >
              {isSent ? (
                <>
                  {getTextSingle(form.sent)}
                  <CheckIcon />
                </>
              ) : (
                getTextSingle(form.send)
              )}
            </Button>
            {isError && (
              <Text type={types.fieldError}>
                {getTextSingle(language.formGeneric.errors.error)}
              </Text>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default PromotionalForm;
