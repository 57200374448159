import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { language, getTextSingle } from "../languages/Languages";

// Backgrounds
import phones from "../assets/backgrounds/contact_img.png";

// Components
import Section from "../components/Section/Section";
import ContactForm from "../components/ContactForm/ContactForm";
import SectionTitle from "../components/SectionTitle/SectionTitle";
import styles from "../config/styles";
import FormContainer from "../components/FormContainer/index";

const useStyles = makeStyles(theme => ({
  bigIcon: {
    marginTop: theme.spacing(6)
  },
  formSection: {
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper
  },
  sectionBottom: {
    padding: theme.spacing(4)
  },
  form: {
    ...styles.mixins.form,
    backgroundColor: theme.palette.background.default,
    boxShadow: "10px 20px 60px #00000010",
    borderRadius: 30,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      width: "100%",
      boxShadow: "none",
      background: "transparent",
      padding: 0
    }
  },
  formData: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  phones: {
    ...styles.mixins.icon,
    display: "flex",
    width: "50%",
    margin: `${theme.spacing(2)}px 0px`,
    marginRight: theme.spacing(4),
    backgroundImage: `url(${phones})`,
    backgroundPosition: "top center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 330,
      margin: 0
    }
  }
}));

const ContactSupport = () => {
  const classes = useStyles();
  // Text
  const { intro } = language.pages.contactSupport;
  const { title, description } = intro;

  return (
    <Section isMain>
      <SectionTitle
        title={getTextSingle(title)}
        description={getTextSingle(description)}
      />
      <FormContainer className={classes.form}>
        <div className={classes.phones} />
        <ContactForm className={classes.formData} />
      </FormContainer>
    </Section>
  );
};

export default ContactSupport;
