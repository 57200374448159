import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Text, { types } from "../Text/Text";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(0)
    }
  },
  title: {
    margin: `0.5em 0`
  },
  titleDescription: {
    width: "52%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  }
}));

const SectionTitle = ({
  className,
  children,
  title,
  description,
  information,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root} {...rest}>
      <Text className={classes.title} type={types.sectionTitle} component="h1">
        {title}
      </Text>
      {description && (
        <Text
          className={classes.titleDescription}
          type={types.titleDescription}
        >
          {description}
        </Text>
      )}
      {information && (
        <Text
          className={classes.titleDescription}
          type={types.titleInformation}
        >
          {information}
        </Text>
      )}
    </div>
  );
};

export default SectionTitle;
