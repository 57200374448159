import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";

// Assets
import headingArrow from "../../../assets/icons/heading-arrow.svg";
import background from "../../../assets/backgrounds/incident-details-bg.png";

// Components
import Text, { types } from "../../Text/Text";

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(1)
  },
  headingArrow: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      height: 8,
      marginRight: theme.spacing(1)
    }
  },
  title: {
    fontSize: 56,
    maxWidth: 716,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      fontSize: 28,
      maxWidth: 425
    }
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4)
    }
  },
  image: {
    width: 410,
    [theme.breakpoints.down("md")]: {
      width: 273
    }
  }
}));

const IncidentDetailsContent = () => {
  const classes = useStyles();
  return (
    <div>
      <Text type={types.subsectionHeading} className={classes.heading}>
        <img src={headingArrow} alt="" className={classes.headingArrow} />
        Incident Details
      </Text>
      <Text type={types.subsectionTitle} className={classes.title}>
        Document what happened, when and where
      </Text>
      <Text type={types.subsectionSubtitle} className={classes.subtitle}>
        All the important details can be stored
      </Text>
      <img src={background} alt="" className={classes.image} />
    </div>
  );
};

export default IncidentDetailsContent;
