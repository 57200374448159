import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { Button, Slide } from "@material-ui/core";

// Components
import CookieSwitch from "../../containers/CookiePolicy/CookieSwitch";
import LinkScroll from "../LinkScroll/LinkScroll";
import Text, { types } from "../Text/Text";

// Config
import { actions } from "../../state/Cookies/actions";
import { getRoute, urls } from "../../config/urls";
import { getText } from "../../utils/textUtils";
import { language } from "../../languages/Languages";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Analytics
import { useGoogleConsent } from "../../context/GoogleConsentProvider";
import setCookie, { cookieKey } from "../../utils/cookieManager";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: `24px ${styles.values.standardMargin}`,
    background: "rgba(0,0,0,0.9)",
    position: "fixed",
    bottom: 0,
    zIndex: 10000000,
    color: theme.palette.primary.contrastText,
    maxHeight: "80vh",
    overflowY: "auto",
    [theme.breakpoints.down("md")]: {
      padding: "24px"
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2, 1)
    }
  },
  shortBanner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      padding: theme.spacing(2, 1)
    }
  },
  shorBannerButtons: {
    display: "flex",
    flexDirection: "row",
    maxHeight: 70,
    [theme.breakpoints.down("md")]: {
      minWidth: 382
    }
  },
  title: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  description: {
    fontSize: 14,
    fontFamily: "Gibson"
  },
  button: {
    color: colors.white,
    fontWeight: "bold",
    padding: theme.spacing(1, 4),
    borderRadius: 40,
    fontSize: 12,
    textTransform: "none",
    boxShadow: "none",
    backgroundColor: colors.lightBlue,
    "&:hover": {
      backgroundColor: colors.lightBlue
    },
    marginLeft: theme.spacing(1)
  },
  link: {
    color: colors.lightBlue
  },
  customize: {
    color: "white",
    fontSize: 14,
    width: 180,
    textTransform: "none"
  },
  consent: {
    color: "white",
    fontSize: 14,
    textTransform: "none"
  },
  shortDescription: {
    width: "65%",
    marginRight: "5%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      letterSpacing: "-1px",
      marginRight: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: theme.spacing(2)
    }
  }
}));

const CookiesBanner = ({ className, children, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { cookies: cooks, isBannerOpen } = useSelector(state => state.cookies);
  const { analytical, functionality, targeting } = cooks;
  const { dispatchConsentListeners } = useGoogleConsent();

  const { permissions, banner } = language.pages.cookiePolicy;
  const { title: permTitle, cookies, shortDescription } = permissions;

  const updateCookies = (attribute, newValue) => {
    dispatch(actions.setCookies({ [attribute]: newValue }));
  };

  const hidePanel = newCookies => {
    dispatch(actions.setPanel(false));
    setCookie(cookieKey.cookieSettings, cooks);
    dispatchConsentListeners(newCookies || cooks);
  };

  const acceptAll = () => {
    const allCookies = {
      analytical: true,
      functionality: true,
      targeting: true,
      strictlyNecessary: true
    };
    dispatch(actions.setCookies(allCookies));
    hidePanel(allCookies);
    setCookie(cookieKey.cookieSettings, allCookies);
    setOpen(true);
  };

  const Panel = (
    <>
      <Slide
        direction="up"
        in={isBannerOpen && isOpen}
        mountOnEnter
        unmountOnExit
      >
        <div className={`${classes.root} ${className}`} {...rest}>
          <div className={classes.title}>
            <Text
              className={classes.settingsTitle}
              type={types.termsElementTitle}
            >
              {getText(permTitle)}
            </Text>
            <Button
              className={classes.button}
              variant="contained"
              onClick={hidePanel}
            >
              DONE
            </Button>
          </div>
          <CookieSwitch
            title={getText(cookies.necessary.title)}
            description={getText(cookies.necessary.description)}
            checked
          />
          <CookieSwitch
            title={getText(cookies.analytical.title)}
            description={getText(cookies.analytical.description)}
            checked={analytical}
            onChange={({ target }) =>
              updateCookies("analytical", target.checked)
            }
          />
          <CookieSwitch
            title={getText(cookies.functionality.title)}
            description={getText(cookies.functionality.description)}
            checked={functionality}
            onChange={({ target }) =>
              updateCookies("functionality", target.checked)
            }
          />
          <CookieSwitch
            title={getText(cookies.targeting.title)}
            description={getText(cookies.targeting.description)}
            checked={targeting}
            onChange={({ target }) =>
              updateCookies("targeting", target.checked)
            }
          />
          <Text className={classes.description}>
            {getText(banner.goToPoliciy)}
            <LinkScroll
              to={`${urls.cookie}#pageStart`}
              onClick={hidePanel}
              className={classes.link}
            >
              {getText(banner.policyLink)}
            </LinkScroll>
          </Text>
        </div>
      </Slide>
      <Slide
        direction="up"
        in={isBannerOpen && !isOpen}
        mountOnEnter
        unmountOnExit
      >
        <div className={`${classes.root} ${className}`} {...rest}>
          <div className={classes.shortBanner}>
            <Text
              className={classes.shortDescription}
              type={types.getAppDescription}
            >
              {getText(shortDescription.first)}{" "}
              <a className={classes.link} href={getRoute(urls.cookie)}>
                {` ${getText(shortDescription.cookieLinkText)} `}
              </a>
              {getText(shortDescription.second)}
              <LinkScroll
                className={classes.link}
                to={`${getRoute(urls.cookie)}#cookieSettings`}
              >
                {` ${getText(shortDescription.preferencesLink)} `}
              </LinkScroll>
              {getText(shortDescription.third)}
            </Text>
            <div className={classes.shorBannerButtons}>
              <Button
                onClick={() => setOpen(true)}
                className={classes.customize}
              >
                {getText(shortDescription.customize)}
              </Button>
              <Button
                className={classes.button}
                variant="contained"
                onClick={acceptAll}
              >
                {getText(shortDescription.consent)}
              </Button>
            </div>
          </div>
        </div>
      </Slide>
    </>
  );

  return Panel;
};

export default CookiesBanner;
