import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// Components
import Text, { types } from "../Text/Text";

// Langauge and config
import { language, getTextSingle } from "../../languages/Languages";
import { urls } from "../../config/urls";
import LinkScroll from "../LinkScroll/LinkScroll";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "90%",
    marginTop: theme.spacing(14),
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(4)
    },
    textAlign: "center"
  },
  info: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center"
    }
  },
  contactUs: {
    padding: theme.spacing(2.5, 0),
    margin: theme.spacing(5, 0),
    fontWeight: "bold",
    width: "100%",
    borderRadius: theme.spacing(1),
    textTransform: "none",
    fontFamily: "Maven Pro",
    fontSize: 24,
    [theme.breakpoints.down("xs")]: {
      width: 300,
      fontSize: 17,
      margin: theme.spacing(2, 0)
    }
  }
}));

const CantFind = ({ className, children, ...rest }) => {
  const classes = useStyles();
  const { title, description, contactUs } = language.cantFind;

  return (
    <div className={`${classes.root} ${className}`} {...rest}>
      <div className={classes.info}>
        <Text type={types.cantFindTitle}>{getTextSingle(title)}</Text>
        <Text type={types.cantFindDescription}>
          {getTextSingle(description)}
        </Text>
        <LinkScroll to={`${urls.contactSupport}#pageStart`}>
          <Button
            className={classes.contactUs}
            color="primary"
            variant="contained"
          >
            {getTextSingle(contactUs)}
          </Button>
        </LinkScroll>
      </div>
    </div>
  );
};

export default CantFind;
