import colors from "./colors";

const values = {
  annotationFooterHeight: 126,
  border: `1px solid ${colors.mystic}`,
  boxShadow: `0px 1px 0px ${colors.mystic}`,
  boxShadowMedium: `0px 3px 6px #00000029`,
  statusBarHeight: 102,
  topBarHeight: 140,
  topBarHeightMid: 80,
  headerHeight: 172,
  standardMargin: "6.5%",
  footerHeight: 410,
  poweredLogoHeight: 70,
  emergencyBannerHeight: 123
};

const mixins = {
  mainSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingTop: values.topBarHeight
  },
  mainSectionMid: {
    paddingTop: values.topBarHeightMid
  },
  sectionContainer: {
    maxWidth: 1168,
    width: "100%",
    margin: "0 auto"
  },
  icon: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
  },
  form: {
    width: "80%",
    maxWidth: 2400,
    display: "flex",
    justifyContent: "center",
    padding: `32px 8%`
  }
};

export default {
  values,
  mixins
};
