import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  useMediaQuery,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";

// Icons
import generic from "../../assets/backgrounds/userTypes/Generic_bco.svg";
import genericSel from "../../assets/backgrounds/userTypes/Generic.svg";
import genericMobile from "../../assets/backgrounds/userTypes/Generic_black.png";
import med from "../../assets/backgrounds/userTypes/Med_Ico_bco.svg";
import medSel from "../../assets/backgrounds/userTypes/Med_Ico.svg";
import medMobile from "../../assets/backgrounds/userTypes/user_med_black.png";
import pol from "../../assets/backgrounds/userTypes/PO_ico_bco.svg";
import polSel from "../../assets/backgrounds/userTypes/PO_ico.svg";
import polMobile from "../../assets/backgrounds/userTypes/police_ico.png";

// Components
import Section from "../../components/Section/Section";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Tac from "../../components/TermsAndConditions/TermsAndConditions";
import UserTypeSelector from "./UserTypeSelector";
import TableElement from "./TableElement";

import { language, getTextSingle } from "../../languages/Languages";
import { getTermsAndConditions } from "../../state/Terms/actions";
import { userTypes } from "../../utils/UserTypes";
import { getText } from "../../utils/textUtils";
import Text, { types as textTypes } from "../../components/Text/Text";
import { getRoute, urls } from "../../config/urls";

const useStyles = makeStyles(theme => ({
  intro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    borderRadius: theme.spacing(3)
  },
  userTypeTitle: {
    margin: `${theme.spacing(4)}px 0`,
    alignSelf: "flex-start"
  },
  userTypes: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: theme.spacing(4),
    width: "100%",
    backgroundColor: "transparent",
    "& .MuiExpansionPanelDetails-root": {
      flexDirection: "column"
    },
    "& .MuiExpansionPanelSummary-content.Mui-expanded": {
      margin: 0
    },
    "& .MuiExpansionPanelSummary-root, .MuiExpansionPanelDetails-root": {
      padding: 0,
      width: "100%"
    },
    "& .MuiCollapse-container": {
      width: "100%"
    },
    "& .MuiExpansionPanelSummary-content": {
      margin: 0
    },
    "&:before": {
      display: "none"
    },
    [theme.breakpoints.down("xs")]: {
      borderRadius: theme.spacing(2),
      boxShadow: "7px 7px 40px 0px #00000029"
    }
  },
  contents: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    paddingTop: theme.spacing(6)
  },
  termsPolicy: {
    width: "100%",
    maxWidth: "768px"
  },
  tableOfContents: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginRight: theme.spacing(4),
    minWidth: 270,
    [theme.breakpoints.down("md")]: {
      minWidth: 170
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  contentElement: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    textAlign: "left",
    marginBottom: theme.spacing(2),
    "& .selected": {
      color: theme.palette.secondary.main,
      fontWeight: "bold"
    },
    "&:focus": {
      outline: "none"
    }
  },
  divisionBar: {
    width: "100%",
    backgroundColor: "#e6e6e6",
    height: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  }
}));

const HowToCapturePage = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("xs"));
  const { userType: initialUserType } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  // State
  const [userType, setUserType] = useState(
    initialUserType || userTypes.medicalProfessional
  );
  const [panelOpen, setPanelOpen] = useState(false);
  // Reducer Data
  const {
    termsData,
    privacyData,
    isLoadingTerms,
    isLoadingPrivacy,
    error,
    errorPrivacy
  } = useSelector(state => state.terms);
  // Text
  const { intro, terms, privacy } = language.pages.termsAndConditions;
  const { title } = intro;
  const { types, title: typesTitle } = terms.userTypes;
  // Table of Content
  const { title: tableTitle } = terms.tableOfContent;
  // Privacy Policy
  const { title: privacyTitle } = privacy;
  // Types
  const { medicalProfessional, policeOfficer, others } = types;

  const contentList = [
    {
      title: isMobile
        ? getTextSingle(others.titleMobile)
        : getTextSingle(others.title),
      description: getTextSingle(others.description),
      value: "Relative",
      icon: generic,
      iconSelected: isMobile ? genericMobile : genericSel
    },
    {
      title: isMobile
        ? getTextSingle(medicalProfessional.titleMobile)
        : getTextSingle(medicalProfessional.title),
      description: getTextSingle(medicalProfessional.description),
      value: userTypes.medicalProfessional,
      icon: med,
      iconSelected: isMobile ? medMobile : medSel
    },
    {
      title: isMobile
        ? getTextSingle(policeOfficer.titleMobile)
        : getTextSingle(policeOfficer.title),
      description: getTextSingle(policeOfficer.description),
      value: userTypes.policeOfficer,
      icon: pol,
      iconSelected: isMobile ? polMobile : polSel
    }
  ];

  useEffect(() => {
    const countryId = "GB";
    if (!termsData && !isLoadingTerms && !error) {
      dispatch(
        getTermsAndConditions({
          countryId,
          userType,
          agreementType: "EndUserLicenseAgreement"
        })
      );
    }
  }, [termsData, isLoadingTerms, dispatch, error, userType]);

  useEffect(() => {
    const countryId = "GB";
    if (!privacyData && !isLoadingPrivacy && !errorPrivacy) {
      dispatch(
        getTermsAndConditions({
          countryId,
          userType,
          agreementType: "PrivacyPolicy"
        })
      );
    }
    // Redirects to the section with the ID after the page is done loading
    else if (privacyData && window.location.hash === "#policies") {
      window.location.hash = "#policies";
    }
  }, [privacyData, isLoadingPrivacy, dispatch, errorPrivacy, userType]);

  useEffect(() => {
    const countryId = "GB";
    if (!isLoadingTerms) {
      dispatch(
        getTermsAndConditions({
          countryId,
          userType,
          agreementType: "EndUserLicenseAgreement"
        })
      );
    }
    if (!isLoadingPrivacy) {
      dispatch(
        getTermsAndConditions({
          countryId,
          userType,
          agreementType: "PrivacyPolicy"
        })
      );
    }
  }, [userType, dispatch]);

  const TableOfContents = () => (
    <div className={classes.tableOfContents}>
      <Text type={textTypes.supportLineTitle}>{getText(tableTitle)}</Text>
      <div className={classes.divisionBar} />
      {contentList.map(content => {
        const selected = content.value === userType;
        return (
          <TableElement
            key={content.value}
            onClick={() => setUserType(content.value)}
            selected={selected}
            title={content.title}
            userType={content.value}
          />
        );
      })}
    </div>
  );

  const UserSelector = () => {
    if (!isMobile) {
      return (
        <div className={classes.userTypes}>
          {contentList.map(content => {
            const selected =
              content.value.toLowerCase() === userType.toLowerCase()
                ? "selected"
                : "";
            return (
              <UserTypeSelector
                key={`UserTypeSelector-${content.value}`}
                title={content.title}
                description={content.description}
                selected={selected}
                icon={selected ? content.icon : content.iconSelected}
                userType={content.value}
                onClick={() => setUserType(content.value)}
              />
            );
          })}
        </div>
      );
    }

    const selected = contentList.find(
      content => content.value.toLowerCase() === userType.toLowerCase()
    );
    const notSelected = contentList.filter(
      content => content.value.toLowerCase() !== userType.toLowerCase()
    );

    if (!selected) {
      history.push(
        getRoute(urls.termsAndConditions, { userType: contentList[0].value })
      );
    }

    return (
      <ExpansionPanel
        className={classes.userTypes}
        square
        expanded={panelOpen}
        onChange={() => setPanelOpen(!panelOpen)}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <UserTypeSelector
            key={`UserTypeSelector-${selected.value}`}
            title={selected.title}
            description={selected.description}
            selected={selected}
            icon={selected.iconSelected}
            userType={selected.value}
          />
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {notSelected.map(content => (
            <UserTypeSelector
              key={`UserTypeSelector-${content.value}`}
              title={content.title}
              description={content.description}
              selected={false}
              icon={content.iconSelected}
              userType={content.value}
              onClick={() => {
                setUserType(content.value);
                setPanelOpen(false);
              }}
            />
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  };

  return (
    <Section isMain>
      <div className={classes.intro}>
        <SectionTitle
          title={getTextSingle(title)}
          description={getTextSingle(typesTitle)}
        />
        {UserSelector()}
        <div className={classes.contents}>
          <TableOfContents />
          <div className={classes.termsPolicy}>
            <Tac
              id="termsAndConditions"
              title={getTextSingle(title)}
              text={termsData || error}
              isLoading={isLoadingTerms}
            />
            <Tac
              id="policies"
              title={getTextSingle(privacyTitle)}
              text={privacyData || error}
              isLoading={isLoadingPrivacy}
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HowToCapturePage;
