import { cookieKey, getCookies } from "../../utils/cookieManager";
import { types } from "./actions";

const cookieSavedSettings = getCookies(cookieKey.cookieSettings, true);
// checks if functionality is activated and if it is loads saved values
const settingsFinalValue = cookieSavedSettings || {};

const INITIAL_STATE = {
  cookies: {
    strictlyNecessary: true,
    analytical: true,
    functionality: false,
    targeting: false,
    ...settingsFinalValue
  },
  isBannerOpen: !settingsFinalValue.strictlyNecessary
};

const cookies = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_COOKIES:
      return {
        ...state,
        cookies: { ...state.cookies, ...action.cookies }
      };
    case types.SET_PANEL:
      return {
        ...state,
        isBannerOpen: action.isOpen
      };
    default:
      return state;
  }
};

export default cookies;
