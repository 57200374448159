const scrollToTargetAdjusted = (elementId, offset) => {
  const element = document.getElementById(elementId);
  const headerOffset = offset;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth"
  });
};

export default scrollToTargetAdjusted;
