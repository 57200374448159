import { useEffect, useMemo, useState } from "react";

const useIsInViewport = (ref, options) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        {
          threshold: 1.0,
          ...options
        }
      ),
    []
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export const useIsInViewportStatic = (ref, options) => {
  const isInViewport = useIsInViewport(ref, options);
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    if (isInViewport) setTriggered(true);
  }, [isInViewport]);

  return triggered;
};

export default useIsInViewport;
