/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useDispatch } from "react-redux";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";

// Components
import Text, { types } from "../../components/Text/Text";
import VideoModal from "../../components/VideoModal/VideoModal";

// Assets
import play from "../../assets/backgrounds/play.svg";
import backgroundImage from "../../assets/backgrounds/light-blue-background.png";
import phoneApp from "../../assets/backgrounds/phones-app-video.png";

// Config
import { getText } from "../../utils/textUtils";
import { language } from "../../languages/Languages";
import styles from "../../config/styles";

// Actions
import { openVideoModal } from "../../state/TutorialVideos/actions";

const useStyles = makeStyles(theme => ({
  howItWorks: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    marginTop: theme.spacing(10),
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    color: "white",
    height: 900,
    [theme.breakpoints.down("md")]: {
      height: 620
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  play: {
    height: "20vw",
    width: "20vw",
    opacity: 0.9,
    backgroundImage: `url(${play})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    cursor: "pointer",
    transition: "all .1s ease-in-out",
    borderRadius: "100%",
    [`&:hover`]: {
      transform: "scale(1.1)",
      opacity: 1
    }
  },
  playContainer: {
    position: "absolute"
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "35%"
  },
  title: {
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
      marginBottom: theme.spacing(3),
      color: "inherit"
    }
  },
  description: {
    padding: theme.spacing(2, 0.5),
    width: "80%",
    textAlign: "left"
  },
  phones: {
    display: "flex",
    width: "40%",
    height: 800,
    ...styles.mixins.icon,
    backgroundImage: `url(${phoneApp})`
  }
}));

const HowItWorksMini = ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { campaign } = language.pages.home;
  const { title, description } = campaign;

  const playVideo = () => dispatch(openVideoModal());

  return (
    <div className={classes.howItWorks} {...rest}>
      <div className={classes.playContainer}>
        <ButtonBase className={classes.play} onClick={playVideo} />
      </div>
      <div className={classes.info}>
        <Text
          className={classes.title}
          type={types.campaignTitle}
          component="h2"
        >
          {getText(title)}
        </Text>
        <Text className={classes.description} type={types.titleDescription}>
          {getText(description)}
        </Text>
      </div>
      <div className={classes.phones} />
      <VideoModal />
    </div>
  );
};

export default HowItWorksMini;
