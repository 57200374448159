import React from "react";

// Material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

// Assets
import evidenceLawyerBg from "../../assets/backgrounds/evidence-lawyer-bg.png";
import reportBg from "../../assets/backgrounds/report-incident-bg.png";
import storingMobileBg from "../../assets/backgrounds/storing-evidence-mobile-bg.png";

// Components
import Section from "../Section/Section";
import Text, { types } from "../Text/Text";

// Config
import colors from "../../config/colors";

// Contents
import StoringEvidenceDesktop from "./StoringEvidenceDesktop";
import HomeSubsectionResponsive from "../HomeSubsection/HomeSubsectionResponsive";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 1500,
    paddingBottom: 100,
    paddingRight: "2.4vw",
    paddingLeft: "2.4vw",
    [theme.breakpoints.down("md")]: {
      minHeight: 1000,
      paddingBottom: 40
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
      paddingBottom: 0
    }
  },
  mainTitle: {
    maxWidth: 1344,
    textTransform: "capitalize",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      maxWidth: 896
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 530
    }
  },
  brandSpan: {
    color: colors.lightBlue
  }
}));

const subsections = [
  {
    id: "doNothing",
    heading: "Do Nothing",
    description: "Your data will not be shared with anyone.",
    title: "That’s entirely your choice",
    bigText: true,
    descriptions: [
      <>
        <span>Kulpa</span> is your safe space.
      </>,
      "Any data captured in, or uploaded to, the app is safely secured on our secure cloud servers, all of which are internationally accredited for their information security (ISO/IEC 270001).",
      "Your data will not be shared with anyone, unless you choose to submit it to the police or send it to another third party via the app.",
      "If you have not submitted the case to the police, you can delete your data at any time. But, once it’s gone, it's gone for good and cannot be retrieved.",
      "Ultimately, it’s your data and you have complete control. You choose what happens to it and what it’s used for. But whatever you choose, it will be safe and secure."
    ],
    mobileBg: storingMobileBg
  },
  {
    id: "lawyer",
    heading: "Send The Evidence To A Lawyer",
    title: "So that they can help you take a civil action",
    bigText: true,
    description: "They can help you take a civil action.",
    descriptions: [
      <>
        You can use the app to quickly and easily send your data to a{" "}
        <span>kulpa</span> accredited lawyer who will provide you with free
        legal advice and support*
      </>,
      "They can then hopefully assist you in obtaining whatever civil order you need, whether that be a protective, restraining or other order.",
      <>
        Alternatively, you can send your <span>kulpa</span> data to a solicitor
        of your choosing. Simply enter their email and mobile phone number.
      </>,
      "They will receive an encrypted file with all of the evidence, accompanied by an exhibiting statement so that it can be used in any civil legal process.",
      "*Free legal assistance may be subject to a means test."
    ],
    background: evidenceLawyerBg
  },
  {
    id: "injuries",
    heading: "Report The Incident To The Police",
    description: "Police will receive immediately usable evidence.",
    title:
      "So that they can quickly safeguard you and hopefully, prosecute the offender",
    descriptions: [
      <>
        When you use <span>kulpa</span> to report a crime, the police will
        receive a package of immediately usable evidence.
      </>,
      "The police will receive all of the evidence which you have stored, accompanied by an automatically generated evidential statement exhibiting all of that evidence, together with a metadata report.",
      "The evidence is therefore verified and immediately legally admissible.",
      "Essentially, they will have everything they need to interview the suspect and hopefully charge them.",
      "No longer do first interviews need to be conducted with sparse or sporadic evidence. Instead, a full and compelling case can be presented to the accused.",
      "With a clear charging decision, the offender can be detained and the victim protected from any further harm.",
      "As the app captures all of the required metadata, a physical download of your entire device will not be required in a majority of cases.",
      "Now, victims can have the confidence to save evidence and report the crime when they are ready, without the risk that their entire device will be downloaded and their entire life and personal information unnecessarily exposed."
    ],
    background: reportBg
  }
];

const mainTitle = element => (
  <>Securely storing evidence on the {element} cloud gives you options</>
);
const mainDescription = "Here they are";

const StoringEvidenceSection = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Section className={classes.root}>
      <Text
        type={types.miniSectionTitle}
        className={classes.mainTitle}
        component="h2"
      >
        {mainTitle(<span className={classes.brandSpan}>kulpa</span>)}
      </Text>
      <Text type={types.titleDescription}>{mainDescription}</Text>
      {isMobile ? (
        <HomeSubsectionResponsive subsections={subsections} />
      ) : (
        <StoringEvidenceDesktop subsections={subsections} />
      )}
    </Section>
  );
};

export default StoringEvidenceSection;
