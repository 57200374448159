import { getVideoUrl } from "../../config/Api";

export const types = {
  VIDEO_MODAL_OPEN: "VIDEO_MODAL_OPEN",
  VIDEO_MODAL_CLOSE: "VIDEO_MODAL_CLOSE",
  VIDEOS_REQUEST: "VIDEOS_REQUEST",
  VIDEOS_SUCCESS: "VIDEOS_SUCCESS",
  VIDEOS_FAILURE: "VIDEOS_FAILURE"
};

export const openVideoModal = ({ index = 0, playList = [] } = {}) => ({
  type: types.VIDEO_MODAL_OPEN,
  payload: { index, playList }
});
export const closeVideoModal = () => ({
  type: types.VIDEO_MODAL_CLOSE
});

export const videoRequest = () => ({
  type: types.VIDEOS_REQUEST
});
export const videoSucess = videos => ({
  type: types.VIDEOS_SUCCESS,
  payload: videos
});
export const videoFailure = () => ({
  type: types.VIDEOS_FAILURE
});

export const GetTutorialVideos = () => dispatch => {
  dispatch(videoRequest());
  fetch(getVideoUrl(), {
    method: "GET",
    headers: {
      accept: "application/json"
    }
  })
    .then(response => response.json())
    .then(data => {
      dispatch(videoSucess(data));
    })
    .catch(({ message }) => {
      dispatch(videoFailure(message));
    });
};
