import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

// Config
import colors from "../../config/colors";

// Background
import { getTextSingle, language } from "../../languages/Languages";
import { externalRoutes } from "../../config/urls";
import { analyticsClassNames } from "../../config/googleAnalitycs";

const useStyles = makeStyles(theme => ({
  loginStyle: {
    padding: theme.spacing(1, 6),
    borderRadius: 40,
    fontSize: 16,
    textTransform: "none",
    boxShadow: "none",
    fontFamily: "Maven Pro",
    backgroundColor: colors.lightBlue,
    "&:hover": {
      backgroundColor: colors.lightBlue
    },
    color: colors.white
  }
}));

const LoginButton = props => {
  const classes = useStyles();
  const { className, children, ...rest } = props;
  // Text
  const { login } = language.common;

  return (
    <a
      className={analyticsClassNames.downloadLink}
      href={externalRoutes.webapp}
      rel="noopener noreferrer"
      target="_blank"
    >
      <Button
        {...rest}
        className={`${className} ${classes.loginStyle}`}
        variant="contained"
      >
        {getTextSingle(login)}
      </Button>
    </a>
  );
};

export default LoginButton;
