import React from "react";

// Material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

// Assets
import background from "../../assets/backgrounds/evidence-collection-bg.png";

// Desktop Backgrounds
import supportingBg from "../../assets/backgrounds/supporting-evidence-bg.png";
import injuriesBg from "../../assets/backgrounds/evidence-injuries-bg.png";
import recordsBg from "../../assets/backgrounds/medical-records-bg.png";
import journalBg from "../../assets/backgrounds/journal-entries-bg.png";
import infoBg from "../../assets/backgrounds/info-requests-bg.png";

// Mobile Backgrounds
import incidentMobileBg from "../../assets/backgrounds/incident-mobile-bg.png";
import supportingMobileBg from "../../assets/backgrounds/supporting-mobile-bg.png";
import injuriesMobileBg from "../../assets/backgrounds/injuries-mobile-bg.png";
import recordsMobileBg from "../../assets/backgrounds/records-mobile-bg.png";
import journalMobileBg from "../../assets/backgrounds/journal-mobile-bg.png";
import infoMobileBg from "../../assets/backgrounds/info-mobile-bg.png";
import storingBg1 from "../../assets/backgrounds/storingBg1.svg";
import storingBg2 from "../../assets/backgrounds/storingBg2.svg";

// Config
import colors from "../../config/colors";

// Components
import Section from "../Section/Section";
import Text, { types } from "../Text/Text";

// Contents
import EvidenceCollectionDesktop from "./EvidenceCollectionDesktop";
import IncidentDetailsContent from "./contents/IncidentDetailsContent";
import HomeSubsectionResponsive from "../HomeSubsection/HomeSubsectionResponsive";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    minHeight: 1500,
    paddingBottom: 100,
    position: "relative",
    overflowX: "hidden",
    width: "100vw",
    paddingRight: "2.4vw",
    paddingLeft: "2.4vw",
    [theme.breakpoints.down("md")]: {
      minHeight: 1100,
      paddingBottom: 40
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: 0,
      backgroundImage: "none",
      backgroundColor: colors.lightGrey
    }
  },
  storingBg1: {
    pointerEvents: "none",
    position: "absolute",
    top: -10,
    left: -40
  },
  storingBg2: {
    pointerEvents: "none",
    position: "absolute",
    top: 360,
    right: 0,
    transform: "translateX(70px)"
  },
  mainTitle: {
    maxWidth: 700,
    textAlign: "center",
    zIndex: 99
  }
}));

const subsections = [
  {
    id: "incident",
    heading: "Incident Details",
    description: "Tell us what happened",
    content: <IncidentDetailsContent />,
    title: "Document what happened, when and where",
    descriptions: ["All the important details can be stored"],
    mobileBg: incidentMobileBg
  },
  {
    id: "evidence",
    heading: "Supporting Evidence",
    description: "Upload any and all supporting evidence",
    title:
      "Any file, any size.\nUpload it to make sure it’s safe, secure and holds evidential weight",
    descriptions: [
      "Kulpa will capture and store all of the metadata associated with any files uploaded.",
      "With this and our accreditation for BS10008 (Evidential Weight and Legal Admissibility of Electronic Information), any and all evidence saved to and submitted via the app is already verified and is therefore immediately legally admissible for use in both civil or criminal proceedings.",
      "It also means that, in a majority of cases, when users choose to report an incident to the police, the police will not need to undertake a physical download of their entire personal device.",
      "Whether it's images, videos, audio, messages, documents, CCTV or anything else, upload it to kulpa.",
      "If you need any advice on what to upload or how, please just get in touch on email or via the chat bubble."
    ],
    background: supportingBg,
    mobileBg: supportingMobileBg
  },
  {
    id: "injuries",
    heading: "Physical Injuries",
    description: "Add Injury",
    title:
      "Use our forensic technology to take scaled photographs of any physical injuries",
    descriptions: [
      "Kulpa’s proprietary technology enables anyone with a camera phone and a card to capture forensic imagery.",
      "Photographs of physical injuries captured with the app will be legally admissible as forensic evidence.",
      "Why? Because the in-app camera uses technology that enables a scale accurate to 1 millimetre to be applied to the image. Which means that it meets the legal requirements to qualify as forensic imagery.",
      "On a related note, any photographs or videos taken with the in-app camera will not be stored locally on the device and so there is no risk that they will be inadvertently seen by others."
    ],
    background: injuriesBg,
    mobileBg: injuriesMobileBg
  },
  {
    id: "records",
    heading: "Medical Records",
    description: "Add relevant medical records",
    title: "Upload any relevant medical records, notes or proof of treatment",
    descriptions: [
      "Medical records can provide very strong corroborating evidence.",
      "By uploading those that are relevant to the kulpa app you will ensure that they are safe and secure and that they can be utilised to support your case in a civil or criminal procedure should you wish to proceed with one.",
      "It can be a long process for the police or another third party to obtain your records from a health provider, even with your consent, so it will significantly speed up the process if you can provide them yourself by uploading them to the kulpa app.",
      "You can either photograph the records or proof of treatment using the in app-camera or upload the records as a file (All file types and sizes are accepted. pdf. doc. jpg.  png. etc)."
    ],
    background: recordsBg,
    mobileBg: recordsMobileBg
  },
  {
    id: "journal",
    heading: "Journal Entries / Statements",
    description:
      "Your Journal entries will not be included as evidence unless you choose to convert them into a formal witness statement.",
    title:
      "This is your safe space to document your story, including your thoughts, feelings and experiences",
    descriptions: [
      "If you choose to send the evidence to a lawyer or submit the incident to the police, your journal entries will not form part of the evidential package. Instead, your entries will remain completely private and will only be visible to you.",
      "If you do want to share any entries and include them as evidence, navigate to the specific entry and click, 'publish as statement'.",
      "It can be difficult to know what to write or where to start, so we have included some top tips within the app. To read them just click on the lightbulb icon."
    ],
    background: journalBg,
    mobileBg: journalMobileBg
  },
  {
    id: "info",
    heading: "Information Requests",
    description:
      "Additional information can be requested from other case contributors",
    title:
      "Invite others to contribute evidence. You can also chat to them using the app’s secure chat function",
    descriptions: [
      "Other people may have evidence that can support your case. It’s important that this evidence is uploaded to the kulpa cloud to ensure that it’s verifiable, holds evidential weight and is immediately legally admissible.",
      "To ask someone to contribute evidence you first need to invite them to the case as a contributor. They will then receive a notification. Once they have accepted the invitation, you will be able to chat to them via the app’s secure chat function and they will be able to upload whatever it is you need.",
      "The best upload is one which starts from the device on which the evidence originated. i.e. If it’s an audio file or a video then it should be uploaded from the phone on which it was originally recorded. If it’s a CCTV recording then use the kulpa web-app to upload it directly from the computer. If it’s a WhatsApp conversation, export the chat and then upload the zip file. If you need any advice on what to upload or how, please just get in touch on email or via the chat bubble."
    ],
    background: infoBg,
    mobileBg: infoMobileBg
  }
];

const mainTitle = `We've Made Evidence Collection Easy`;
const mainDescription = `Here's what you can privately store`;

const EvidenceCollectionSection = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Section className={classes.root} id="evidence">
      {isMobile && (
        <>
          <img src={storingBg1} alt="" className={classes.storingBg1} />
          <img src={storingBg2} alt="" className={classes.storingBg2} />
        </>
      )}
      <Text
        type={types.miniSectionTitle}
        className={classes.mainTitle}
        component="h2"
      >
        {mainTitle}
      </Text>
      <Text type={types.titleDescription} component="p">
        {mainDescription}
      </Text>
      {isMobile ? (
        <HomeSubsectionResponsive subsections={subsections} />
      ) : (
        <EvidenceCollectionDesktop subsections={subsections} />
      )}
    </Section>
  );
};

export default EvidenceCollectionSection;
