import React from "react";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "@material-ui/core/Paper";
import Zoom from "@material-ui/core/Zoom";

// Assets
import iconAddLight from "../../assets/icons/icon-add-light.png";
import arrowRight from "../../assets/icons/back-arrow-right.svg";

// Components
import Text, { types } from "../Text/Text";
import colors from "../../config/colors";

const useStyles = makeStyles(theme => ({
  button: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: theme.spacing(1.2),
    padding: theme.spacing(2.5),
    backgroundColor: "#fff",
    boxShadow: "0px 1px 3px #0000000A",
    transition: "all .2s ease-in-out",
    width: 400,
    position: "relative",
    borderRadius: 4,
    [theme.breakpoints.down("md")]: {
      width: 260,
      padding: theme.spacing(1.5),
      marginBottom: theme.spacing(1)
    }
  },
  buttonSelected: {
    boxShadow: "0px 1px 63px #0000001C",
    transform: "translateX(60px)"
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(0.5)
    }
  },
  titleDivider: {
    width: "100%",
    marginBottom: theme.spacing(2),
    position: "relative",
    "&:after": {
      content: '""',
      width: "100%",
      height: 1,
      backgroundColor: colors.doveGray,
      position: "absolute",
      left: 0,
      bottom: theme.spacing(-1),
      opacity: "10%"
    }
  },
  arrowRight: {
    position: "absolute",
    top: theme.spacing(3),
    right: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(1.5),
      right: theme.spacing(1.5),
      height: 8
    }
  },
  iconAddLight: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(0.5),
      height: 8
    }
  },
  contentPanel: {
    marginLeft: "50%",
    outline: "1px solid red",
    width: "50%"
  }
}));

const HomeSubsectionNavButton = ({
  description,
  isLast,
  isSelected,
  onClick,
  title,
  isInViewport,
  delay
}) => {
  const classes = useStyles();
  return (
    <Zoom
      direction="right"
      in={isInViewport}
      timeout={500}
      style={{ transitionDelay: `${delay}ms` }}
    >
      <div>
        <ButtonBase
          className={clsx(classes.button, {
            [classes.buttonSelected]: isSelected
          })}
          component={Paper}
          elevation={0}
          onClick={onClick}
        >
          <img src={arrowRight} alt="" className={classes.arrowRight} />
          <Text
            type={types.buttonLinkTitle}
            className={isLast ? classes.titleDivider : classes.title}
          >
            {title}
          </Text>
          <Text type={types.buttonLinkDescription}>
            {!isLast && (
              <img alt="" className={classes.iconAddLight} src={iconAddLight} />
            )}
            {description}
          </Text>
        </ButtonBase>
      </div>
    </Zoom>
  );
};

const areEqual = (prev, next) =>
  prev.isInViewport === next.isInViewport &&
  prev.isSelected === next.isSelected;

export default React.memo(HomeSubsectionNavButton, areEqual);
