import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Config
import { getRoute, urls } from "../../config/urls";
import colors from "../../config/colors";
import styles from "../../config/styles";

// Components
import LinkScroll from "../../components/LinkScroll/LinkScroll";
import Text, { types } from "../../components/Text/Text";

const useStyles = makeStyles(theme => ({
  intro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    position: "relative",
    width: 300,
    height: 270,
    margin: theme.spacing(1),
    textAlign: "left",
    cursor: "pointer",
    padding: theme.spacing(3),
    backgroundColor: ({ selected }) =>
      selected ? colors.lightBlue : colors.white,
    color: ({ selected }) =>
      selected
        ? theme.palette.primary.contrastText
        : theme.palette.primary.main,
    borderRadius: theme.spacing(3),
    transition: "background-color 0.5s",
    boxShadow: "7px 7px 40px 0px #00000029",
    [theme.breakpoints.down("sm")]: {
      width: 200,
      height: 200
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      width: "100%",
      height: 85,
      padding: theme.spacing(1),
      margin: theme.spacing(0),
      alignItems: "center",
      color: () => theme.palette.primary.main,
      backgroundColor: () => "transparent",
      borderColor: ({ selected }) => (selected ? "none" : colors.lightGrey),
      border: "none",
      borderRadius: theme.spacing(2),
      boxShadow: "none"
    }
  },
  info: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    fontWeight: "bold",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: "none",
      marginLeft: theme.spacing(3)
    }
  },
  userSelector: {
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      display: "none",
      borderRadius: theme.spacing(2)
    }
  },
  contractText: {
    width: "100%"
  },
  icon: {
    ...styles.mixins.icon,
    width: "100%",
    height: 70,
    backgroundPositionX: "right",
    marginBottom: theme.spacing(4),
    backgroundImage: ({ icon }) => `url(${icon})`,
    [theme.breakpoints.down("sm")]: {
      height: 50
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      minWidth: 32,
      maxWidth: 32,
      margin: theme.spacing(1),
      marginRight: theme.spacing(2)
    }
  }
}));

const UserTypeSelector = ({
  title,
  description,
  icon,
  selected = false,
  userType,
  ...others
}) => {
  const classes = useStyles({ icon, selected });
  return (
    <LinkScroll
      className={classes.intro}
      to={`${getRoute(urls.termsAndConditions, {
        userType
      })}${selected ? "" : "#termsAndConditions"}`}
      {...others}
    >
      <div className={classes.icon} />
      <div className={classes.info}>
        <Text className={classes.title} type={types.answer}>
          {title}
        </Text>
        <Text className={classes.userSelector} type={types.answer}>
          {description}
        </Text>
      </div>
    </LinkScroll>
  );
};

export default UserTypeSelector;
