import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

// FontAwesome
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faTiktok,
  faYoutube,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Config
import { externalRoutes } from "../../config/urls";
import colors from "../../config/colors";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  iconButton: {
    minWidth: "auto",
    width: 40,
    height: 40,
    borderRadius: "100%",
    marginRight: theme.spacing(1),
    "&:last-of-type": {
      marginRight: 0
    },
    [theme.breakpoints.down("md")]: {
      width: 28,
      height: 28,
      padding: 0
    }
  },
  faIcon: {
    fontSize: 18,
    color: colors.socialIcon,
    [theme.breakpoints.down("md")]: {
      fontSize: 12
    }
  }
}));

const socialItems = [
  {
    icon: faFacebookF,
    id: "facebook",
    url: externalRoutes.facebook
  },
  {
    icon: faTwitter,
    id: "twitter",
    url: externalRoutes.twitter
  },
  {
    icon: faInstagram,
    id: "instagram",
    url: externalRoutes.instagram
  },
  {
    icon: faTiktok,
    id: "tiktok",
    url: externalRoutes.tikTok
  },
  {
    icon: faYoutube,
    id: "youtube",
    url: externalRoutes.youTube
  },
  {
    icon: faLinkedin,
    id: "linkedin",
    url: externalRoutes.linkedin
  }
];

const SocialLinks = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {socialItems.map(({ icon, id, url }) => (
        <Button
          className={classes.iconButton}
          component="a"
          href={url}
          key={id}
          target="blank"
          variant="outlined"
        >
          <FontAwesomeIcon className={classes.faIcon} icon={icon} />
        </Button>
      ))}
    </div>
  );
};

export default SocialLinks;
