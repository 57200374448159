import { combineReducers } from "redux";
import constant from "lodash-es/constant";
import createReducer from "../../utils/createReducer";

import { types } from "./actions";

const {
  PRESS_RELEASE_LIST_REQUEST,
  PRESS_RELEASE_LIST_SUCCESS,
  PRESS_RELEASE_LIST_FAIL,
  PRESS_RELEASE_REQUEST,
  PRESS_RELEASE_SUCCESS,
  PRESS_RELEASE_FAIL
} = types;

const pressReleasesList = createReducer(null, {
  [PRESS_RELEASE_LIST_SUCCESS]: (state, { payload }) => payload,
});

const pressRelease = createReducer(null, {
  [PRESS_RELEASE_SUCCESS]: (state, { payload }) => payload,
});

const loading = createReducer(false, {
  [PRESS_RELEASE_REQUEST]: constant(true),
  [PRESS_RELEASE_SUCCESS]: constant(false),
  [PRESS_RELEASE_FAIL]: constant(false),
});

const loadingList = createReducer(false, {
  [PRESS_RELEASE_LIST_REQUEST]: constant(true),
  [PRESS_RELEASE_LIST_SUCCESS]: constant(false),
  [PRESS_RELEASE_LIST_FAIL]: constant(false),
});

export default combineReducers({
  pressReleasesList,
  loadingList,
  pressRelease,
  loading
});
