import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  mainColor: {
    color: theme.palette.secondary.main,
    fontWeight: 600
  }
}));

const HighlightColor = ({ children, className, ...props }) => {
  const classes = useStyles();
  return (
    <span className={`${classes.mainColor} ${className}`} {...props}>
      {children}
    </span>
  );
};

export default HighlightColor;
