export const urls = {
  home: "/",
  victimSupport: "/victimSupport",
  cookie: "/cookiePolicy",
  // Forms
  contactSupport: "/contactSupport",
  promotionalMaterial: "/promotionalMaterial",
  requestInclusion: "/requestInclusion",
  // Product Info
  faqs: "/faqs",
  termsAndConditions: "/termsAndConditions/:userType",
  pressReleases: "/pressReleases",
  pressReleasesWithId: "/pressReleases/:pressReleaseId"
};

export default urls;

export const externalRoutes = {
  googlePlay: "https://play.google.com/store/apps/details?id=io.kulpa.kulpa",
  appStore: "https://apps.apple.com/gb/app/kulpa/id1609323523",
  webapp: "https://www.kulpacloud.com/download",
  twitter: "https://twitter.com/kulpacloud",
  linkedin: "https://www.linkedin.com/company/kulpacloud",
  facebook: "https://www.facebook.com/kulpacloud",
  instagram: "https://www.instagram.com/kulpacloud/",
  youTube: "https://www.youtube.com/channel/UCE5SlOcj4lLXqtpK8eHJESw",
  tikTok: "https://www.tiktok.com/@kulpacloud",
  microsoftPartner: "https://partner.microsoft.com/en-gb/",
  techUk: "https://www.techuk.org/",
  teamPolice: "https://www.teampolice.uk/",
  emergencyServices: "https://www.emergencyuk.com/",
  edac: "https://www.edacuk.org",
  learnWhy:
    "https://www.kulpacloud.com/pressReleases/89e6456d-fc30-431c-8825-167db305e83f#pageStart"
};

// Internal Routes
export const getRoute = (url, values = {}) => {
  let path = url;

  Object.keys(values).forEach(key => {
    path = url.replace(new RegExp(`:${key}`, "i"), values[key]);
  });

  return `${path}${values.query || ""}`;
};
