const colors = {
  baliHai: "#8F9BB3",
  black: "#000",
  mineShaft: "#1F1F1F",
  cloudBurst: "#222B45",
  mystic: "#EAEDF3",
  lightBlue: "#31C8DE",
  rose: "#E6007E",
  grey: "#A1A4AC",
  lightGrey: "#F7F7F7",
  riverBed: "#384152",
  nightBlue: "#333F50",
  whisper: "#FBFBFD",
  white: "#FFFFFF",
  error: "#E6007E",
  greenCorrect: "#50E3C2",
  emergencyHeader: "#000000",
  socialIcon: "#9A9A9A",
  santasGray: "#9EA0A5",
  doveGray: "#707070"
};

export default colors;
