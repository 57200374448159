const baseApiUrl = `${
  process.env.NODE_ENV === "development" ? "https://stage.kulpacloud.com" : ""
}/api/v1/`;

export const instrumentationKey =
  process.env.NODE_ENV === "development"
    ? "5f37d587-8fe2-4d4c-96fb-f03ed913778a"
    : process.env.REACT_APP_INSTRUMENTATION_KEY;

export const getLegalAgreementsUrl = ({
  countryId,
  userType,
  agreementType,
  format = "Html"
}) =>
  `${baseApiUrl}legalAgreements?agreementType=${agreementType}&country=${countryId}&format=${format}${
    userType ? `&userType=${userType}` : ""
  }`;

export const getFaqsUrl = `${baseApiUrl}faqs`;

export const getPressReleaseUrl = () => `${baseApiUrl}pressReleases`;

export const getVideoUrl = () => `${baseApiUrl}website/tutorialVideos/`;

export const getSupportUrl = () => `${baseApiUrl}support/requestInformation`;

export const getPromotionalMaterialUrl = () =>
  `${baseApiUrl}support/requestPromotionalMaterials`;

export const getInclusionRequestUrl = () =>
  `${baseApiUrl}support/victimSupportConsent`;

export const getIntroVideoId = () => `259b2c0a-950b-4165-8d33-932008614db6`;

export const getCertificatesUrl = () => `${baseApiUrl}accreditations`;

export const getCertificateIdUrl = id => `${baseApiUrl}accreditations/${id}`;

export const getSupportLinesUrl = (latitude, longitude) => {
  if (latitude && longitude)
    return `${baseApiUrl}supportservices?latitude=${latitude}&longitude=${longitude}`;
  return `${baseApiUrl}supportservices`;
};

export const getEmergencyContacts = (latitude, longitude) => {
  if (latitude && longitude)
    return `${baseApiUrl}emergencyContacts?latitude=${latitude}&longitude=${longitude}`;
  return `${baseApiUrl}emergencyContacts`;
};
