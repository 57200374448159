import { combineReducers } from "redux";
import constant from "lodash-es/constant";
import createReducer from "../../utils/createReducer";

import { types } from "./actions";

const {
  VIDEO_MODAL_OPEN,
  VIDEO_MODAL_CLOSE,
  VIDEOS_REQUEST,
  VIDEOS_FAILURE,
  VIDEOS_SUCCESS
} = types;

const isModalOpen = createReducer(false, {
  [VIDEO_MODAL_OPEN]: constant(true),
  [VIDEO_MODAL_CLOSE]: constant(false)
});

const index = createReducer(null, {
  [VIDEO_MODAL_OPEN]: (state, { payload }) => payload.index
});

const videos = createReducer(null, {
  [VIDEOS_SUCCESS]: (state, { payload }) => payload
});

const error = createReducer(null, {
  [VIDEOS_FAILURE]: (state, payload) => payload
});

const loading = createReducer(false, {
  [VIDEOS_REQUEST]: constant(true),
  [VIDEOS_FAILURE]: constant(false),
  [VIDEOS_SUCCESS]: constant(false)
});

const playList = createReducer(null, {
  [VIDEO_MODAL_OPEN]: (state, { payload }) => [...payload.playList]
});

export default combineReducers({
  isModalOpen,
  index,
  loading,
  videos,
  error,
  playList
});
