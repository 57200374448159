import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import { SwipeableDrawer, List, ButtonBase } from "@material-ui/core";

// Images - Social
import twitter from "../../assets/logos/socialMedia/Twitter.svg";
import linkedin from "../../assets/logos/socialMedia/Likedin.svg";
import facebook from "../../assets/logos/socialMedia/Facebook.svg";
import instagram from "../../assets/logos/socialMedia/Instagram.svg";
import tiktok from "../../assets/logos/socialMedia/Tiktok.svg";
import youtube from "../../assets/logos/socialMedia/Youtube.svg";

// Config
import { urls, getRoute, externalRoutes } from "../../config/urls";
import { language } from "../../languages/Languages";

// Utils
import { getText } from "../../utils/textUtils";

// Components
import { userTypes } from "../../utils/UserTypes";
import LinkScroll from "../LinkScroll/LinkScroll";
import LoginButton from "../LoginButton/LoginButton";
import styles from "../../config/styles";
import Text from "../Text/Text";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDrawer-paperAnchorLeft": {
      right: 0,
      maxWidth: "100vw",
      display: "flex",
      justifyContent: "space-between",
      padding: "0 5%",
      paddingBottom: theme.spacing(6),
      background: theme.palette.background.default
    }
  },
  drawerTop: {
    height: 70,
    minHeight: 70,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  headerIcon: {},
  closeButton: {
    color: theme.palette.secondary.main,
    borderRadius: 4,
    fontSize: 20,
    padding: "4px 8px"
  },
  optionList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2)
  },
  loginButton: {
    marginBottom: theme.spacing(4)
  },
  mobileRoute: {
    fontSize: 24,
    fontFamily: "Gibson",
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  mobileRouteLesser: {
    fontSize: 18,
    fontFamily: "Montserrat",
    marginTop: theme.spacing(2)
  },
  draweTextDivision: {
    fontSize: 18,
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: theme.palette.secondary.main
  },
  draweTextDivisionTop: {
    marginTop: theme.spacing(4)
  },
  selectedLine: {
    width: "0%",
    height: 3,
    backgroundColor: theme.palette.secondary.main,
    "&.selected": {
      width: "35%",
      transition: "width 0.3s"
    }
  },
  innerFooter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
    "& .MuiTypography-root": {
      fontFamily: "Maven Pro",
      fontSize: 14
    }
  },
  socialList: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2)
  },
  mediaImage: {
    ...styles.mixins.icon,
    width: 40,
    height: 40,
    marginRight: theme.spacing(1),
    filter: "brightness(0.1)"
  }
}));

const MobileDrawer = ({ isOpened, onClose, anchor = "left", ...rest }) => {
  const [opened, setOpened] = useState(isOpened);
  const { pathname } = useLocation();
  const classes = useStyles();
  let lastTimeout = null;
  useEffect(() => {
    setOpened(isOpened);
  }, [isOpened]);

  const onToogle =
    (toggle, isQuick = false) =>
    () => {
      if (lastTimeout) {
        clearTimeout(lastTimeout);
      }
      lastTimeout = setTimeout(
        () => {
          setOpened(toggle);
          if (!toggle && onClose) onClose();
        },
        isQuick ? 0 : 250
      );
    };

  const renderRoute = (text, url, isLesser) => {
    const isSelected = pathname.includes(url);
    const selected = isSelected ? "selected" : "";
    return (
      <>
        <LinkScroll to={`${url}`} onClick={onToogle(false)}>
          <Text
            className={
              isLesser ? classes.mobileRouteLesser : classes.mobileRoute
            }
          >
            {getText(text)}
          </Text>
        </LinkScroll>
        <div className={`${classes.selectedLine} ${selected}`} />
      </>
    );
  };

  return (
    <SwipeableDrawer
      {...rest}
      anchor={anchor}
      open={opened}
      onClose={onToogle(false)}
      onOpen={onToogle(false)}
      className={classes.root}
    >
      <div>
        <div className={classes.drawerTop}>
          <LinkScroll
            className={classes.headerIcon}
            to={`${urls.home}#pageStart`}
            onClick={onToogle(false)}
          >
            <Text className={classes.draweTextDivision}>
              {getText(language.header.mobile.menu)}
            </Text>
          </LinkScroll>
          <ButtonBase
            className={classes.closeButton}
            onClick={onToogle(false, true)}
          >
            X
          </ButtonBase>
        </div>
        <List className={classes.optionList}>
          <LoginButton className={classes.loginButton} />
          {renderRoute(language.pages.faq.intro.title, urls.faqs)}
          {renderRoute(
            language.pages.victimSupport.intro.title,
            getRoute(urls.victimSupport)
          )}
          {renderRoute(
            language.pages.contactSupport.intro.title,
            `${getRoute(urls.contactSupport)}#pageStart`
          )}
          {renderRoute(
            language.pages.termsAndConditions.intro.title,
            `${getRoute(urls.termsAndConditions, {
              userType: userTypes.relatives
            })}#pageStart`,
            true
          )}
          {renderRoute(
            language.pages.termsAndConditions.privacy.title,
            `${getRoute(urls.termsAndConditions, {
              userType: userTypes.relatives
            })}#policies`,
            true
          )}
          {renderRoute(
            language.pages.cookiePolicy.title,
            `${getRoute(urls.cookie)}#pageStart`,
            true
          )}
          {renderRoute(
            language.pages.pressRelease.title,
            `${getRoute(urls.pressReleases)}#pageStart`,
            true
          )}
        </List>
      </div>
      <div>
        <div className={classes.socialList}>
          <a
            href={externalRoutes.twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={twitter} className={classes.mediaImage} alt="twitter" />
          </a>
          <a
            href={externalRoutes.linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} className={classes.mediaImage} alt="twitter" />
          </a>
          <a
            href={externalRoutes.facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={facebook} className={classes.mediaImage} alt="facebook" />
          </a>
          <a
            href={externalRoutes.instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={instagram}
              className={classes.mediaImage}
              alt="instagram"
            />
          </a>
          <a
            href={externalRoutes.tikTok}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tiktok} className={classes.mediaImage} alt="tikToc" />
          </a>
          <a
            href={externalRoutes.youTube}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={youtube} className={classes.mediaImage} alt="youTube" />
          </a>
        </div>
        <div className={classes.innerFooter}>
          <Text>{getText(language.footer.madeWith.with)}</Text>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default MobileDrawer;
