import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

// Material
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";
import { AppBar, useMediaQuery, IconButton, Zoom } from "@material-ui/core";

// Images
import kulpaLogo from "../../assets/logos/kulpa-logo.png";
import menuIcon from "../../assets/icons/menu_icon.png";

//  Utils
import { getText } from "../../utils/textUtils";
import { language } from "../../languages/Languages";

// Config
import { urls } from "../../config/urls";
import styles from "../../config/styles";

// Components
import LinkScroll from "../LinkScroll/LinkScroll";
import LoginButton from "../LoginButton/LoginButton";
import MobileDrawer from "./MobileDrawer";
import SocialLinks from "../SocialLinks/SocialLinks";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    transition: "background-color 0.5s, box-shadow 0.5s",
    backgroundColor: "#FFFFFF",
    boxShadow: ({ sticky }) => (sticky ? "0px 3px 76px #00000029" : "none")
  },
  appBarContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    padding: `0 ${styles.values.standardMargin}`,
    height: styles.values.topBarHeight,
    [theme.breakpoints.down("md")]: {
      height: styles.values.topBarHeightMid
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 5%",
      height: 70,
      justifyContent: "space-between"
    }
  },
  colorInherit: {
    color: theme.palette.common.black
  },
  headerOptions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: theme.spacing(8),
    marginLeft: "auto"
  },
  headerLink: {
    position: "relative",
    textDecoration: "none",
    marginLeft: theme.spacing(6),
    fontFamily: ["Maven Pro", "Open Sans", "sans-serif"].join(","),
    fontSize: 17,
    textAlign: "center",
    "&:visited": {
      color: "inherit"
    }
  },
  loginButton: {
    marginLeft: theme.spacing(6),
    fontFamily: ["Maven Pro", "Open Sans", "sans-serif"].join(","),
    fontSize: 17,
    [theme.breakpoints.down("md")]: {
      width: 100,
      height: 38,
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  selected: {
    fontWeight: "bold"
  },
  headerIcon: {
    ...styles.mixins.icon,
    display: "flex",
    width: 175,
    minWidth: 175,
    height: 50,
    backgroundImage: `url(${kulpaLogo})`,
    [theme.breakpoints.down("md")]: {
      width: 126,
      minWidth: 126,
      height: 28
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: 0,
      padding: "0 5%",
      width: 120,
      height: 25,
      justifyContent: "space-between"
    }
  },
  menuIcon: {
    ...styles.mixins.icon,
    display: "flex",
    width: 25,
    height: 25,
    backgroundImage: `url(${menuIcon})`
  },
  line: {
    position: "absolute",
    bottom: -1 * theme.spacing(1.5),
    width: "100%",
    height: 4,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

const Header = () => {
  const [sticky, setSticky] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const { pathname } = useLocation();

  const classes = useStyles({ sticky });
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const {
    header: { victimSupport, technicalSupport, faqs }
  } = language;

  useEffect(() => {
    const handleScroll = () => setSticky(window.scrollY > 70);
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderLink = (url, text) => {
    const isSelected = pathname === url;
    return (
      <LinkScroll
        className={clsx(classes.headerLink, {
          [classes.selected]: isSelected
        })}
        to={`${url}#pageStart`}
      >
        {getText(text)}
        {isSelected && (
          <Zoom in>
            <div className={classes.line} />
          </Zoom>
        )}
      </LinkScroll>
    );
  };

  return (
    <AppBar className={classes.root} position="fixed" color="inherit">
      <header className={classes.appBarContent}>
        <LinkScroll
          className={classes.headerIcon}
          to={`${urls.home}#pageStart`}
        />
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setMobileDrawerOpen(true)}
            >
              <div className={classes.menuIcon} />
            </IconButton>
            <MobileDrawer
              isOpened={mobileDrawerOpen}
              onClose={() => setMobileDrawerOpen(false)}
            />
          </>
        ) : (
          <>
            <nav className={classes.headerOptions}>
              {renderLink(urls.faqs, faqs)}
              {renderLink(urls.victimSupport, victimSupport)}
              {renderLink(urls.contactSupport, technicalSupport)}
              <LoginButton className={classes.loginButton} />
            </nav>
            <SocialLinks />
          </>
        )}
      </header>
    </AppBar>
  );
};

export default Header;
