const fromFileToBase64 = (file, onFinish) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const data = reader.result.split("base64,")[1];
    onFinish({
      fileName: file.name,
      mimeType: file.type,
      data
    });
  };
};

export const getPhoneNumberFormat = () =>
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export default fromFileToBase64;
